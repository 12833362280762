import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import ContactForm from "./ContactForm";
import LocationMap from "./Map";
import styles from './contact.module.css';

function ContactUs() {
    const locations = [
        { city: "Pensacola, Florida", address: "215 W Herman Ave, Pensacola, Florida 32505", phone: "(850) 432-7787", mapLink: "https://www.google.com/maps/place/CES+Team+One+Communications,+Inc./@30.4481484,-87.2360182,16z/data=!4m16!1m9!3m8!1s0x8890bf773d996291:0x813a29b0467088ea!2sCES+Team+One+Communications,+Inc.!8m2!3d30.4481438!4d-87.2334433!9m1!1b1!16s%2Fg%2F11b75l1s9b!3m5!1s0x8890bf773d996291:0x813a29b0467088ea!8m2!3d30.4481438!4d-87.2334433!16s%2Fg%2F11b75l1s9b?entry=ttu"},
        { city: "Mobile, Alabama", address: "3360 Key St, Mobile, Alabama, 36609", phone: "(251) 343-2560", mapLink: "https://www.google.com/maps/place/Team+One+Communications/@30.665023,-88.129903,15z/data=!4m16!1m9!3m8!1s0x889a4d081101d3a9:0x369479f0036b9721!2sTeam+One+Communications!8m2!3d30.665023!4d-88.129903!9m1!1b1!16s%2Fg%2F1txfpyfn!3m5!1s0x889a4d081101d3a9:0x369479f0036b9721!8m2!3d30.665023!4d-88.129903!16s%2Fg%2F1txfpyfn?entry=ttu" },
        { city: "Pascagoula, Mississippi", address: "1777 Old Mobile Ave, Pascagoula Mississippi, 39567", phone: "(228) 762-3721", mapLink: "https://www.google.com/maps/place/Team+One+Communications/@30.366129,-88.5465038,15z/data=!4m6!3m5!1s0x889be8c58edf3d8b:0xa89cf8969b77f2c6!8m2!3d30.366129!4d-88.5465038!16s%2Fg%2F1v27w8gz?entry=ttu" },
       
    ];

    return (
        <div className={styles.contact} role="main">
            <div className={styles.splashContainer} style={{ backgroundImage: `url('/assets/home/pcola-beach1.jpg')` }} aria-labelledby="contact-title">
                <div className={styles.splash}>
                    
                </div>
            </div>
            <Container className={styles.mainContent}>
                <Row>
                    <Col md={6} className={styles.locations}>
                        <h2>Our Locations</h2>
                        <Row>
                            {locations.map((location, index) => (
                                <Col md={6} key={index}>
                                    <Card className={styles.locationCard}>
                                        <Card.Body>
                                            <Card.Title className={styles.cardTitle}>{location.city}</Card.Title>
                                            <div className={styles.cardDivider}></div>
                                            <Card.Text>
                                                <a href={location.mapLink} target="_blank" rel="noopener noreferrer" className={styles.iconLink} aria-label={`Map location for ${location.city}`}>
                                                    <FaMapMarkerAlt className={styles.icon} />
                                                    {location.address}
                                                </a>
                                            </Card.Text>
                                            <Card.Text>
                                                <a href={`tel:${location.phone}`} className={styles.iconLink} aria-label={`Call ${location.phone}`}>
                                                    <FaPhoneAlt className={styles.icon} />
                                                    Phone: {location.phone}
                                                </a>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col md={6} className={styles.form}>
                       
                        <ContactForm />
                    </Col>
                </Row>
                <h3>Find Us on The Map</h3>
                <LocationMap aria-labelledby="map-title" />
            </Container>
        </div>
    );
}

export default ContactUs;
