import React from 'react';
import styles from "./public.module.css";
import { Col, Row, Container, Card, Image } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';

const PublicSafety = () => {
    const cardContent = [
        {
            img: '/assets/publicSafety/apx-next-all.png',
            title: 'APX NEXT',
            description: 'All-band radio with LTE, WiFi, Bluetooth. Operate via 3.6” touchscreen, large controls, or full ViQi Voice Control. Available in XE or XN models.'
        },
        {
            img: '/assets/publicSafety/n70.png',
            title: 'APX N70',
            description: 'Single-band radio with optional LTE, WiFi, Bluetooth. Operate via 3” touchscreen, large controls, 6 added programmable buttons or full ViQi Voice Control.'
        },
        {
            img: '/assets/publicSafety/n50.png',
            title: 'APX N50',
            description: 'Single-band radio with WiFi and Bluetooth. Operate via large controls or ViQi Basic Voice Control.'
        },
        {
            img: '/assets/publicSafety/n30.png',
            title: 'APX N30',
            description: 'Single-band radio with WiFi and Bluetooth. Operate via large controls or ViQi Basic Voice Control.'
        },
        {
            img: '/assets/publicSafety/apx6000.jpeg',
            title: 'APX 6000',
            description: 'APX 6000 P25 has WiFi, Adaptive Audio Engine, Bluetooth® 4.0, and V300 body-worn camera integration which can trigger emergency recording.'
        },
        {
            img: '/assets/publicSafety/apx6000xe.jpeg',
            title: 'APX 6000XE',
            description: 'The APX 6000XE radio, designed with firefighters in mind, includes WiFi, Adaptive Audio Engine, and Bluetooth 4.0, supporting rough conditions effectively.'
        },
    ];

    const mobileCardContent = [
        {
            img: '/assets/publicSafety/apx8500.jpg',
            title: 'APX 8500',
            description: 'The APX™ 8500 all-band mobile radio with secure Wi-Fi, integrated Bluetooth, and easy installation. Connects to VML750 LTE vehicle modem via micro USB for data applications. Optional Ethernet faceplate available for direct connection to MG90 LTE modem.'
        },
        {
            img: '/assets/publicSafety/apx6500.png',
            title: 'APX 6500',
            description: 'The evolved APX™ 6500 mobile radio features a rugged, compact design, integrated Wi-Fi, Bluetooth, and SmartConnect, maximizing vehicle space and connectivity. It can automatically alert dispatch upon high impact.'
        },
        {
            img: '/assets/publicSafety/apx4500.jpg',
            title: 'APX 4500',
            description: 'The APX™ 4500 paired with the rugged O2 Control Head offers reliable radio communication in a compact form for easy vehicle installation. Features include integrated hardware encryption, impact detection for automatic dispatch alerts, Wi-Fi for quick software updates, and Bluetooth for wireless communication with (COTS) accessories.'
        },
        {
            img: '/assets/publicSafety/apx1500.jpg',
            title: 'APX 1500',
            description: 'The APX™ 1500 mobile offers reliable and affordable P25 radio communication, featuring a rugged O2 control head with an easy-to-read color display and a built-in 7.5-watt speaker. It ensures seamless interoperability with first responders and includes integrated Bluetooth for wireless communication with (COTS) accessories.'
        }
    ];

    const dispatchConsoleContent = [
        {
            img: '/assets/publicSafety/mcc7500e.png',
            title: 'MCC 7500E IP',
            description: 'The MCC 7500E is a compact workstation designed for easy deployment in space-constrained dispatch centers, featuring dual Ethernet connections to reduce LAN switch failures and Enhanced Integrated IRR for accessing more recorded call audio and metadata.'
        },
        {
            img: '/assets/publicSafety/axs.jpg',
            title: 'AXS Dispatch',
            description: 'Streamline your voice dispatch operations with a configurable, intuitive console that adapts to your protocols and roles, featuring a modern GUI that responds to touch, type, or click. Enjoy simplified software upgrades and mission-critical reliability from over 80 years of innovation, with customizable layouts for various scenarios.'
        },
        {
            img: '/assets/publicSafety/vesta.jpg',
            title: 'VESTA 911',
            description: 'To keep up with evolving technology, VESTA offers reliable and innovative emergency call handling and mapping software that enhances telecommunicator efficiency and confidence. Trusted by 60% of U.S. PSAPs, VESTA includes features like real-time transcription, unified mapping for better situational awareness, community video integration, and advanced cybersecurity to protect against threats.'
        },
        {
            img: '/assets/publicSafety/premierone.gif',
            title: 'PremierOne CAD',
            description: 'Provide better information to dispatchers with a fast and efficient computer-aided dispatch system that integrates all incident information. Design intuitive police CAD workflows that match your agency\'s needs.'
        }
    ];

    return (
        <div className={styles.main} role="main">
            <div
                className={styles.splashImage}
                style={{ backgroundImage: `url('/assets/home/pcola-beach1.jpg')` }}
                role="img"
                aria-label="Background image of a beach"
            />
            <h1>Public Safety Solutions</h1>
            <Container>
                <Row className="align-items-center">
                    <Col md={4} className="d-flex justify-content-center">
                        <Image src='/assets/publicSafety/officer-shake.png' className={styles.img1} fluid alt="Public safety officer shaking hands" />
                    </Col>
                    <Col>
                        <div className={styles.customCard}>
                            <p>
                                For over four decades, since 1981, we have been dedicated to working with and maintaining public safety radio systems. Our extensive experience ensures reliable communication channels for emergency services, aiding in their critical missions to protect and serve communities. Our commitment to excellence and continuous improvement has made us a trusted partner in enhancing public safety through state-of-the-art radio technology and unwavering support.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className={styles.p25}>
                <h2>P25 Radio Suite to meet your demands</h2>
                <Container>
                    <Row>
                        <Col>
                            <div className={styles.customCard}>
                                <p>
                                    Mission-critical operations depend on the performance and reliability of uncompromising communications. This is why we built the APX™ P25 two-way radios with sophisticated technology, toughness, and precise ergonomic controls.
                                </p>
                                <p>
                                    Our broad APX portfolio includes everything from affordable single-band radios and ultra-rugged firefighter radios to all-band radios with advanced touch and voice assistant user interfaces.
                                </p>
                                <p>
                                    All our radios are certified to the APCO Project 25 (P25) digital two-way radio standard, and offer guaranteed interoperability with other agencies and organizations using P25.
                                </p>
                            </div>
                        </Col>
                        <Col md={4} className="d-flex justify-content-center">
                            <Image src='/assets/publicSafety/p25-family.jpeg' className={styles.img1} fluid alt="P25 radio family" />
                        </Col>
                    </Row>
                </Container>

                <h3>Portable Radios</h3>
                <Container>
                    <Row>
                        {cardContent.map((content, index) => (
                            <Col key={index} xs={12} sm={12} md={12} lg={6} xl={6} xxl={4} className="d-flex justify-content-center mb-4">
                                <Card className={styles.card} aria-labelledby={`card-title-${index}`}>
                                    <Card.Img className={styles.cardImg} variant="top" src={content.img} alt={`${content.title} image`} />
                                    <Card.Body>
                                        <Card.Title id={`card-title-${index}`}>{content.title}</Card.Title>
                                        <Card.Text className={styles.cardDesc}>
                                            {content.description}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>

                <h4>Mobiles to fit your radio needs</h4>
                <Container>
                    <Row>
                        {mobileCardContent.map((content, index) => (
                            <Col key={index} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="d-flex justify-content-center mb-4">
                                <Card className={styles.card} aria-labelledby={`mobile-card-title-${index}`}>
                                    <Card.Img className={styles.cardImg} variant="top" src={content.img} alt={`${content.title} image`} />
                                    <Card.Body>
                                        <Card.Title id={`mobile-card-title-${index}`}>{content.title}</Card.Title>
                                        <Card.Text className={styles.cardDesc}>
                                            {content.description}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
            <h5>Dispatch Consoles</h5>
            <Container>
                <Row>
                    {dispatchConsoleContent.map((content, index) => (
                        <Col key={index} xs={12} md={6} className="d-flex justify-content-center mb-4">
                            <Card className={styles.card} aria-labelledby={`dispatch-console-title-${index}`}>
                                <Card.Img className={styles.cardImg} variant="top" src={content.img} alt={`${content.title} image`} />
                                <Card.Body>
                                    <Card.Title id={`dispatch-console-title-${index}`}>{content.title}</Card.Title>
                                    <Card.Text className={styles.cardDesc}>
                                        {content.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            <section className='anchor' id="lpr" aria-labelledby="lpr-heading">
                <div className={styles.lpr}>
                    <h6 id="lpr-heading">Vigilent License Plate Recognition</h6>
                    <Container>
                        <Row className="align-items-center">
                            <Col md={4}>
                                <Image src='/assets/publicSafety/lpr.png' className={`${styles.img2} rounded`} fluid alt="Vigilent License Plate Recognition system" />
                            </Col>
                            <Col md={8}>
                                <Row>
                                    <Col>
                                        <Card className={styles.checkCard}>
                                            <Card.Body className={styles.checkBody}>
                                                <Card.Title className={styles.checkTitle}><FaCheck /> Robust, Accurate Data Capture</Card.Title>
                                                <Card.Text className={styles.checkText}>
                                                    Scan license plates with a variety of camera types, integrations and mobile applications.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card className={styles.checkCard}>
                                            <Card.Body className={styles.checkBody}>
                                                <Card.Title className={styles.checkTitle}><FaCheck /> Exclusive Search & Data Analysis</Card.Title>
                                                <Card.Text className={styles.checkText}>
                                                    Turn your vehicle location data into lead-generating intelligence that helps close cases.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card className={styles.checkCard}>
                                            <Card.Body className={styles.checkBody}>
                                                <Card.Title className={styles.checkTitle}><FaCheck /> Data Sharing & Retention Control</Card.Title>
                                                <Card.Text className={styles.checkText}>
                                                    It's your data - not ours. Easily share it and manage its retention completely on your terms.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
            <section className="anchor" id="in-car-video" aria-labelledby="in-car-video-heading">
                <div className={styles.m500}>
                    <h6 id="in-car-video-heading">M500 Police In-car Video System</h6>
                    <Container>
                        <Row className="align-items-center">
                            <Col md={8}>
                                <Row>
                                    <Col>
                                        <Card className={styles.checkCard}>
                                            <Card.Body className={styles.checkBody}>
                                                <Card.Title className={styles.checkTitle}> Mission-critical design</Card.Title>
                                                <Card.Text className={styles.checkText}>
                                                    From the leading supplier of in-car cameras, the M500 is built on exacting research and real world feedback
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card className={styles.checkCard}>
                                            <Card.Body className={styles.checkBody}>
                                                <Card.Title className={styles.checkTitle}> Industry-leading video</Card.Title>
                                                <Card.Text className={styles.checkText}>
                                                    The M500 delivers the high-resolution, wide-field footage you need to precisely document events as they unfold
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card className={styles.checkCard}>
                                            <Card.Body className={styles.checkBody}>
                                                <Card.Title className={styles.checkTitle}> Embedded Intelligence</Card.Title>
                                                <Card.Text className={styles.checkText}>
                                                    Simultaneously analysing video while it's recording evidence, it's designed to keep you and your communities safer
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Image src='/assets/publicSafety/m500.jpg' className={`${styles.img2} rounded`} fluid alt="M500 Police In-car Video System" />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </div>
    );
}

export default PublicSafety;
