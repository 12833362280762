import React from 'react';
import { Container, Row, Col, Image, Card, Button, Carousel } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import styles from './wave.module.css';
import ContactUsModal from '../ContactUsModal';

const WaveTlk = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const cards = [
        {
            imgSrc: '/assets/wave/tlk100.jpeg',
            title: 'TLK 100',
            buttonText: 'Learn More',
            url: '/radios/LTE/tlk100'
        },
        {
            imgSrc: '/assets/wave/tlk110.jpeg',
            title: 'TLK 110',
            buttonText: 'Learn More',
            url: '/radios/LTE/tlk110'
        },
        {
            imgSrc: '/assets/wave/tlk25.jpeg',
            title: 'TLK 25',
            buttonText: 'Learn More',
            url: '/radios/LTE/tlk25'
        },
        {
            imgSrc: '/assets/wave/tlk150.jpeg',
            title: 'TLK 150',
            buttonText: 'Learn More',
            url: '/radios/LTE/waveptx-app'
        },
        {
            imgSrc: '/assets/wave/wave-app.jpg',
            title: 'PTX App',
            buttonText: 'Learn More',
            url: '/radios/LTE/waveptx-app'
        },
    ];

    const renderCard = (card, index) => (
        <Col key={index}>
            <Card className={styles.tlkCard}>
                <Card.Img className={styles.tlkImg} src={card.imgSrc} alt={card.title} />
                <Card.Body>
                    <Card.Title>{card.title}</Card.Title>
                    <Link to={card.url} className={styles.tlkButton} aria-label={`Learn more about ${card.title}`}>
                    {card.buttonText}
                </Link>
                </Card.Body>
            </Card>
        </Col>
    );

    return (
        <div className={styles.main}>
            <div
                className={styles.splashImage}
                style={{ backgroundImage: `url('/assets/home/pcola-beach1.jpg')` }}
                aria-label="Background image of a beach"
            />
            <h1>WAVE PTX - Nationwide Coverage</h1>
            <p>WAVE PTX is a push-to-talk (PTT) app that instantly connects your team across different devices, networks and locations.</p>
            <Container>
                <Row className="align-items-center">
                    <Col md={4}>
                        <Image src='/assets/wave/wave_ptx.gif' fluid alt="WAVE PTX app" />
                    </Col>
                    <Col md={8}>
                        <Row>
                            <Col>
                                <Card className={styles.waveCard}>
                                    <Card.Body className={styles.cardBody}>
                                        <Card.Title className={styles.cardTitle}><FaCheck />  Instant Communication</Card.Title>
                                        <Card.Text className={styles.cardText}>
                                            Get the speed and simplicity of professional radio PTT communication with the ability to share details via text, photo, video or file attachment.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className={styles.waveCard}>
                                    <Card.Body className={styles.cardBody}>
                                        <Card.Title className={styles.cardTitle}><FaCheck />  GPS Tracking</Card.Title>
                                        <Card.Text className={styles.cardText}>
                                            Professional Radio PTT with GPS Tracking Nationwide.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className={styles.waveCard}>
                                    <Card.Body className={styles.cardBody}>
                                        <Card.Title className={styles.cardTitle}><FaCheck />  Nationwide service</Card.Title>
                                        <Card.Text className={styles.cardText}>
                                            Connect your professional radio system to WAVE PTX and reach more team members over a wider service area.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className={styles.waveCard}>
                                    <Card.Body className={styles.cardBody}>
                                        <Card.Title className={styles.cardTitle}><FaCheck />  Predictable costs</Card.Title>
                                        <Card.Text className={styles.cardText}>
                                            Manage your cash flow with low-cost per-user subscriptions, no capital expenses and no long-term commitments.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <h2>Broadband PTT devices and wireless service</h2>
            <p>Get the benefit of WAVE PTX with a rugged two-way radio on a nationwide network.</p>
            <Container className="mb-6">
                <Row className="align-items-center">
                    <Col md={8}>
                        <Row>
                            <Col>
                                <Card className={styles.waveCard}>
                                    <Card.Body className={styles.cardBody}>
                                        <Card.Title className={styles.cardTitle}>Nationwide push-to-talk</Card.Title>
                                        <Card.Text className={styles.cardText}>
                                            Increase productivity with a nationwide push-to-talk network that powers the quickest push-to-talk setup and call times.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className={styles.waveCard}>
                                    <Card.Body className={styles.cardBody}>
                                        <Card.Title className={styles.cardTitle}>Easy to use and manage</Card.Title>
                                        <Card.Text className={styles.cardText}>
                                            Communicate instantly and manage your business effortlessly by handling talkgroups and subscriptions in real time.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className={styles.waveCard}>
                                    <Card.Body className={styles.cardBody}>
                                        <Card.Title className={styles.cardTitle}>Built for the job</Card.Title>
                                        <Card.Text className={styles.cardText}>
                                            Get the job done with a rugged two-way radio that is built for business with location tracking and Wi-Fi calling.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4}>
                        <Image src='./assets/wave/tlk-seris1.webp' fluid alt="TLK Series Radio" />
                    </Col>
                </Row>
            </Container>
            <ContactUsModal />
            <h3>WAVE PTX Products</h3>
            <Container className={styles.products}>
                {isMobile ? (
                    <Carousel
                        indicators={true}
                        interval={3000}
                        nextIcon={<span aria-hidden="true" className="carousel-control-next-icon custom-next-icon" />}
                        prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon custom-prev-icon" />}
                        aria-label="Product Carousel"
                    >
                        {cards.map((card, index) => (
                            <Carousel.Item key={index}>
                                <Card className={styles.tlkCard}>
                                    <Card.Img className={styles.tlkImg} src={card.imgSrc} alt={card.title} />
                                    <Card.Body>
                                        <Card.Title>{card.title}</Card.Title>
                                        <Link to={card.url} className={styles.tlkButton} aria-label={`Learn more about ${card.title}`}>
                                            {card.buttonText}
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                ) : (
                    <Row>
                        {cards.map((card, index) => renderCard(card, index))}
                    </Row>
                )}
            </Container>
        </div>
    );
};

export default WaveTlk;
