import React from 'react';

const DomecamIcon = ({ className }) => (
    <svg
      id="a"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
    >
      <path
        d="M21.47,33.85v11.11M24.76,7h0c.78,0,1.41,.63,1.41,1.41V24.59h-2.82V8.41c0-.78,.63-1.41,1.41-1.41Zm7.04,11.11h2.82v6.48h-2.82v-6.48Zm7.51,1.85h2.82v4.63h-2.82v-4.63Zm-12.2,17.59h11.27m-11.27-3.7h11.27m-11.27-3.7h11.27m-14.08,26.85h-.94v-10.19h.94v-14.81h-2.82v-7.41h22.53v7.41h-1.88v25H24.29Z"
        fill="none"
        stroke='currentColor'
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
);

export default DomecamIcon;
