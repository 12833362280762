import React, { useState, useEffect, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import styles from './emblaCarousel.module.css';

const Thumb = ({ selected, index, onClick, src }) => (
  <div
    className={`${styles.emblaThumbSlide} ${selected ? styles.emblaThumbSlideSelected : ''}`}
    role="button"
    tabIndex="0"
    onClick={onClick}
    onKeyDown={(e) => e.key === 'Enter' && onClick()}
    aria-label={`Thumbnail ${index}`}
  >
    <img src={src} className={styles.emblaThumbSlideImage} alt={`Thumbnail ${index}`} />
  </div>
);

const EmblaCarousel = ({ slides, options }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel(options);
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true,
  });

  const onThumbClick = useCallback(
    (index) => {
      if (!emblaMainApi || !emblaThumbsApi) return;
      emblaMainApi.scrollTo(index);
    },
    [emblaMainApi, emblaThumbsApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on('select', onSelect).on('reInit', onSelect);
  }, [emblaMainApi, onSelect]);

  return (
    <div className={styles.embla} aria-label="Image carousel">
      <div className={styles.emblaViewport} ref={emblaMainRef}>
        <div className={styles.emblaContainer} role="list">
          {slides.map((src, index) => (
            <div
              className={styles.emblaSlide}
              key={index}
              role="listitem"
            >
              <img src={src} className={styles.emblaSlideImage} alt={`Slide ${index}`} />
            </div>
          ))}
        </div>
      </div>
      <div
        className={styles.emblaThumbs}
        aria-hidden="true" // Hide thumbnail list from screen readers
      >
        <div className={styles.emblaThumbsViewport} ref={emblaThumbsRef}>
          <div className={styles.emblaThumbsContainer} role="list">
            {slides.map((src, index) => (
              <Thumb
                key={index}
                onClick={() => onThumbClick(index)}
                selected={index === selectedIndex}
                index={index}
                src={src}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmblaCarousel;
