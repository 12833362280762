import React from 'react';

const LPRIcon = ({ className }) => (
  <svg
    id="l5m"
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    className={className}
  >
    <rect width="64" height="64" style={{ fill: 'none' }} />
    <g>
      <path
        d="m26.49,31.25c.52,0,.95.42.95.95s-.42.95-.95.95-.95-.42-.95-.95.42-.95.95-.95m0-2c-1.63,0-2.95,1.32-2.95,2.95s1.32,2.95,2.95,2.95,2.95-1.32,2.95-2.95-1.32-2.95-2.95-2.95h0Z"
        style={{ fill: 'currentColor' }}
      />
      <path
        d="m37.51,31.25c.52,0,.95.42.95.95s-.42.95-.95.95-.95-.42-.95-.95.42-.95.95-.95m0-2c-1.63,0-2.95,1.32-2.95,2.95s1.32,2.95,2.95,2.95,2.95-1.32,2.95-2.95-1.32-2.95-2.95-2.95h0Z"
        style={{ fill: 'currentColor' }}
      />
      <g>
        <path
          d="m9.63,26.69c-.6,0-1.08.49-1.08,1.08s.49,1.08,1.08,1.08,1.08-.49,1.08-1.08-.49-1.08-1.08-1.08h0Z"
          style={{ fill: 'currentColor' }}
        />
        <path
          d="m9.63,35.55c-.6,0-1.08.49-1.08,1.08s.49,1.08,1.08,1.08,1.08-.49,1.08-1.08-.49-1.08-1.08-1.08h0Z"
          style={{ fill: 'currentColor' }}
        />
      </g>
      <path
        d="m51.59,25.29l1.1.83s.01,0,.02,0h2.11c.19,0,.37.1.48.26l.74,1.11s.02.04.02.06v9.3s0,.04-.02.06l-.74,1.11c-.11.16-.29.26-.49.26h-2.12l-1.11.84H12.41l-1.1-.83s-.01,0-.02,0h-2.11c-.2,0-.38-.1-.49-.26l-.74-1.11s-.02-.04-.02-.06v-9.3s0-.04.02-.06l.74-1.11c.11-.16.29-.26.49-.26h2.12l1.11-.84h39.19m.67-2H11.74l-.53.4-.58.44h-1.45c-.87,0-1.67.43-2.15,1.15l-.74,1.11c-.23.35-.35.75-.35,1.17v9.3c0,.42.12.82.35,1.17l.74,1.11c.48.72,1.29,1.15,2.15,1.15h1.45l.58.44.53.4h40.52l.53-.4.58-.44h1.45c.87,0,1.67-.43,2.15-1.15l.74-1.11c.23-.35.35-.75.35-1.17v-9.3c0-.42-.12-.82-.35-1.17l-.74-1.12c-.48-.72-1.28-1.15-2.14-1.15h-1.46l-.58-.44-.53-.4h0Z"
        style={{ fill: 'currentColor' }}
      />
      <path
        d="m14.91,27.97h0,0m34.43.46l1.16,2.06v3.43l-1.16,2.06H14.66l-1.16-2.06v-3.43l1.16-2.06h34.69m.59-2H14.07c-.37,0-.71.21-.9.57l-1.5,2.67c-.11.2-.17.43-.17.67v3.75c0,.24.06.47.17.67l1.5,2.67c.2.35.54.57.9.57h35.86c.37,0,.71-.21.9-.57l1.5-2.67c.11-.2.17-.43.17-.67v-3.75c0-.24-.06-.47-.17-.67l-1.5-2.67c-.2-.35-.54-.57-.9-.57h0Z"
        style={{ fill: 'currentColor' }}
      />
      <path
        d="m47.21,40.27h-30.41l-1.38,3.43s0,.03.02.03l2.28-.14h28.57l2.28.14s.03-.01.02-.03l-1.38-3.43Z"
        style={{ fill: 'currentColor', stroke: 'currentColor', strokeLinecap: 'round', strokeMiterlimit: 10, strokeWidth: '2px' }}
      />
      <g>
        <path
          d="m54.31,26.69c-.6,0-1.08.49-1.08,1.08s.49,1.08,1.08,1.08,1.08-.49,1.08-1.08-.49-1.08-1.08-1.08h0Z"
          style={{ fill: 'currentColor' }}
        />
        <path
          d="m54.31,35.55c-.6,0-1.08.49-1.08,1.08s.49,1.08,1.08,1.08,1.08-.49,1.08-1.08-.49-1.08-1.08-1.08h0Z"
          style={{ fill: 'currentColor' }}
        />
      </g>
      <g>
        <path
          d="m27.2,44.72v.6l-.49.07c-.92.13-1.84.19-2.77.19-1.08,0-2.15-.09-3.22-.26h0v-.6h6.48m2-2h-10.48v4.31l1.68.27c1.17.19,2.36.28,3.54.28,1.01,0,2.03-.07,3.04-.21l.49-.07,1.73-.24v-4.35h0Z"
          style={{ fill: 'currentColor' }}
        />
        <path
          d="m43.28,44.72v.6l-.49.07c-.92.13-1.84.19-2.77.19-1.08,0-2.15-.09-3.22-.26v-.6h6.48m2-2h-10.48v4.31l1.68.27c1.17.19,2.36.28,3.54.28,1.01,0,2.03-.07,3.04-.21l.49-.07,1.73-.24v-4.35h0Z"
          style={{ fill: 'currentColor' }}
        />
      </g>
      <path
        d="m35.38,22.37l.79.24h7.66l.67.22h1.58l.85.5h-29.85l.85-.5h1.58l.67-.22h7.66l.79-.24h6.76m.3-2h-7.36l-.29.09-.5.15h-7.68l-.31.1-.36.12h-1.79l-.47.27-.85.5-6.37,3.73h44.61l-6.37-3.73-.85-.5-.47-.27h-1.79l-.36-.12-.31-.1h-7.68l-.5-.15-.29-.09h0Z"
        style={{ fill: 'currentColor' }}
      />
    </g>
  </svg>
);

export default LPRIcon;
