import React from 'react';

const VideoIcon = ({ className }) => (
  <svg
    id="a"
    data-name="Export 01 (HT)"
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    className={className}
  >
    <path
      d="M45.8,33.99c.43,0,.78-.35,.78-.78s-.35-.78-.78-.78-.78,.35-.78,.78,.35,.78,.78,.78Zm-9.37,0c.43,0,.78-.35,.78-.78s-.35-.78-.78-.78-.78,.35-.78,.78,.35,.78,.78,.78Zm-3.39-1.04h15.63v-3.14h-2.08l-2.08-2.09h-4.94l-1.87,1.69-4.65,1.44v2.09Zm-16.67,24.05c5.21-1.39,10.42-2.09,15.62-2.09s10.42,.7,15.62,2.09M17.16,20.92c.43,0,.78-.35,.78-.78s-.35-.78-.78-.78-.78,.35-.78,.78,.35,.78,.78,.78Zm-.16-.92h.5v.5h-.5v-.5Zm-2.04,5.64l1.97-1,1.16-2.14h2.63l1.31,3.14m-5.46,4.81l.85-2.72,2.08-2.09m-1.04-3.14l1.04,3.14,1.04,3.14,2.08,2.09m7.29-6.27h21.88v12.55H29.92v-12.55ZM12.21,16.23h13.54v17.77H12.21V16.23Zm19.79,38.77v-5.32m-25-7.32H57M7,11H57V49.68H7V11Z"
      style={{
        fill: 'none',
        stroke: 'currentColor',
        strokeMiterlimit: 10,
        strokeWidth: '2px',
      }}
    />
  </svg>
);

export default VideoIcon;
