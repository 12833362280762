import React from 'react';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DomecamIcon from '../icons/DomecamIcon';
import DetectorIcon from '../icons/DetectorIcon';
import MassNoti from '../icons/MassNoti';
import OperationsIcon from '../icons/OperationsIcon';
import PhoneLockIcon from '../icons/PhoneLockIcon';
import BodycamIcon from '../icons/BodycamIcon';
import Phone911Icon from '../icons/Phone911Icon';
import VideoIcon from '../icons/VideoIcon';
import PlateIcon from '../icons/PlateIcon';
import RadioIcon from '../icons/RadioIcon';
import PhoneBroadcast from '../icons/PhoneBroadcast';

const Ecosystem = () => {
  const navigate = useNavigate();

  const handleCardClick = (route) => {
    navigate(route);
  };

  return (
    <Container>
      <Row>
        <Col lg={4}>
          <Card 
            className="eco-card" 
            role="button" 
            tabIndex={0} 
            onClick={() => handleCardClick('/ecosystem-detect')} 
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleCardClick('/ecosystem-detect');
              }
            }} 
            aria-label="Detect Section: Identify any issues as they happen"
          >
            <Card.Body>
              <Card.Title>Detect</Card.Title>
              <div className="eco-icons">
                <DomecamIcon className="eco-icon" aria-label="Dome Camera" />
                <PhoneLockIcon className="eco-icon" aria-label="Phone Lock" />
                <BodycamIcon className="eco-icon" aria-label="Body Camera" />
                <DetectorIcon className="eco-icon" aria-label="Detector" />
                <Phone911Icon className="eco-icon" aria-label="911 Phone" />
              </div>
              <Card.Text>Identify any issues as they happen</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={3}>
          <Card 
            className="eco-card" 
            role="button" 
            tabIndex={0} 
            onClick={() => handleCardClick('/ecosystem-analyze')} 
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleCardClick('/ecosystem-analyze');
              }
            }} 
            aria-label="Analyze Section: Evaluate the problem at hand"
          >
            <Card.Body>
              <Card.Title>Analyze</Card.Title>
              <div className="eco-icons">
                <VideoIcon className="eco-icon" aria-label="Video" />
                <PlateIcon className="eco-icon" aria-label="License Plate" />
                <VideoIcon className="eco-icon" aria-label="Video" />
              </div>
              <Card.Text>Evaluate the problem at hand</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={3}>
          <Card 
            className="eco-card" 
            role="button" 
            tabIndex={0} 
            onClick={() => handleCardClick('/ecosystem-communicate')} 
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleCardClick('/ecosystem-communicate');
              }
            }} 
            aria-label="Communicate Section: Inform the right people"
          >
            <Card.Body>
              <Card.Title>Communicate</Card.Title>
              <div className="eco-icons">
                <RadioIcon className="eco-icon" aria-label="Radio" />
                <PhoneBroadcast className="eco-icon" aria-label="Phone Broadcast" />
                <MassNoti className="eco-icon" aria-label="Mass Notification" />
              </div>
              <Card.Text>Inform the right people</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={2}>
          <Card 
            className="eco-card" 
            role="button" 
            tabIndex={0} 
            onClick={() => handleCardClick('/ecosystem-manage')} 
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleCardClick('/ecosystem-manage');
              }
            }} 
            aria-label="Manage Section: Coordinate effective steps moving forward"
          >
            <Card.Body>
              <Card.Title>Manage</Card.Title>
              <div className="eco-icons">
                <OperationsIcon className="eco-icon" aria-label="Operations" />
              </div>
              <Card.Text>Coordinate effective steps moving forward</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Ecosystem;
