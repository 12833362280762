import React from 'react';
import { Card, Col, Row, Container, Image } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';  // Import Link from react-router-dom
import styles from './video.module.css';
import ContactUsModal from '../ContactUsModal';

import PhoneLockIcon from '../icons/PhoneLockIcon';
import BodycamIcon from '../icons/BodycamIcon';
import DomecamIcon from '../icons/DomecamIcon';
import InCarCamIcon from '../icons/InCarCam';
import LPRIcon from '../icons/LPRIcon';

const VideoSurv = () => {

  const simpleCards = [
    { SvgComponent: DomecamIcon, title: 'Fixed Video Security', link: '/video-surveillance#video' },
    { SvgComponent: PhoneLockIcon, title: 'Access Control Systems', link: '/video-surveillance#access' },
    { SvgComponent: BodycamIcon, title: 'Body-Worn Cameras', link: '/body-cameras' },
    { SvgComponent: InCarCamIcon, title: 'In-car Systems', link: '/public-safety#in-car-video' },
    { SvgComponent: LPRIcon, title: 'License Plate Recognition', link: '/public-safety#lpr' },
  ];

  return (
    <main className={styles.main}>
      <div
        className={styles.splashImage}
        style={{ backgroundImage: `url('/assets/home/pcola-beach1.jpg')` }}
        aria-hidden="true"
      />
      <h1>Explore our Video and access control portfolio</h1>
      <p>Whether you are looking to deploy a new video security or access control solution, equip your team with body-worn cameras,<br /> or leverage analytics through purpose-built applications, we have solutions to meet your needs.</p>
      <Container className="mt-4">
        <Row className="justify-content-center">
          {simpleCards.map((card, index) => {
            const SvgComponent = card.SvgComponent;
            return (
              <Col xs={12} sm={6} md={4} lg={2} key={index} className="mb-4 d-flex justify-content-center">
                <Link to={card.link} className={styles.vidIcons} aria-label={card.title}>
                  <Card className={styles.iconCard}>
                    <Card.Body className="text-center">
                      <SvgComponent width="100%" height="auto" />
                      <Card.Title>{card.title}</Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            );
          })}
        </Row>
      </Container>

      <section className='anchor' id="video">
        <Container className={styles.survCont}>
          <Row className="align-items-center">
            <Col xs={12} md={4} className="d-flex justify-content-center mb-3 mb-md-0">
              <Image
                src='/assets/video/avi-cams.jpg'
                className={styles.img2}
                fluid
                alt="Avigilon IP Camera Products"
              />
            </Col>
            <Col xs={12} md={8}>
              <div className={styles.avCard}>
                <h2>Discover Avigilon IP Camera Products</h2>
              </div>
              <div>
                <ul>
                  <li>Dome security cameras</li>
                  <li>Bullet and box cameras</li>
                  <li>Panoramic security cameras</li>
                  <li>PTZ security cameras</li>
                  <li>Specialty security cameras</li>
                  <li>Video infrastructure</li>
                  <li>Access Control Systems</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <ContactUsModal />

      <div className={styles.vms1}>
        <h2>Cloud Video and Security Systems with Avigilons Alta</h2>
        <Container className="mb-6 mx-auto">
          <Row className="align-items-center">
            <Col md={4}>
              <Image src='/assets/video/avi-scan.avif'
                className={styles.img2}
                fluid
                alt="Avigilon Alta Cloud Video"
              />
            </Col>
            <Col md={8}>
              <Row>
                <Col>
                  <Card className={styles.avCard}>
                    <Card.Body className={styles.cardBody}>
                      <Card.Title className={styles.cardTitle}>Achieve true proactive security with AI analytics</Card.Title>
                      <ul>
                        <li>Detect and classify anomalies based on perimeters and behaviors</li>
                        <li>Differentiate between people, vehicles and other objects</li>
                        <li>Map movement across your sites</li>
                        <li>Receive real-time alerts with thumbnails, time and location data</li>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className={styles.avCard}>
                    <Card.Body className={styles.cardBody}>
                      <Card.Title className={styles.cardTitle}>Investigate smarter and faster</Card.Title>
                      <ul>
                        <li>Search one or more cameras and specify a time range</li>
                        <li>Find people and filter by apparent gender and clothing color</li>
                        <li>Locate vehicles based on type, color or license plate</li>
                        <li>Match against a reference image for people or vehicles</li>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className={styles.avCard}>
                    <Card.Body className={styles.cardBody}>
                      <Card.Title className={styles.cardTitle}>Enhance your operational awareness</Card.Title>
                      <ul>
                        <li>Optimize cleaning, heating and cooling based on usage</li>
                        <li>Use hot-desking metrics to improve productivity and planning</li>
                        <li>Understand traffic flows in retail and public venues</li>
                        <li>Identify hot spots and high-traffic areas to improve customer experience</li>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <Container className="mb-6">
          <Row className="align-items-center">
            <Col md={8}>
              <Row>
                <Col>
                  <Card className={styles.avCard}>
                    <Card.Body className={styles.cardBody}>
                      <Card.Title className={styles.cardTitle}>Secure and scalable, on the camera and in the cloud</Card.Title>
                      <ul>
                        <li>Automatic software updates from the cloud</li>
                        <li>End-to-end encryption of data and metadata</li>
                        <li>Secure, remote access via a browser or mobile app</li>
                        <li>Scalable, secure storage on the camera or in the cloud</li>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className={styles.avCard}>
                    <Card.Body className={styles.cardBody}>
                      <Card.Title className={styles.cardTitle}>Get started quickly and integrate with what you use every day</Card.Title>
                      <div>
                        <ul>
                          <li>Connect third-party cameras to add AI analytics and local storage</li>
                          <li>Integrate third-party sensors for additional environmental insights</li>
                          <li>ONVIF compliant talk-down speakers for intrusion deterrence</li>
                          <li>Open API for limitless integration potential</li>
                        </ul>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Image src='/assets/video/av-cloud.avif' fluid alt="Avigilon Alta Cloud Security" />
            </Col>
          </Row>
        </Container>
      </div>

      <section className='anchor' id="access">
        <div className={styles.accessControl}>
          <h3>Access Control Systems</h3>
          <Container className="mb-6 mx-auto">
            <Row className="align-items-center">
              <Col md={4}>
                <Image src='/assets/video/access.avif'
                  className={styles.img2}
                  fluid
                  alt="Access Control Systems"
                />
              </Col>
              <Col md={8}>
                <Row>
                  <Col>
                    <Card className={styles.avCard}>
                      <Card.Body className={styles.cardBody}>
                        <Card.Title className={styles.cardTitle}>Manage from anywhere, on any device</Card.Title>
                        <Card.Text className={styles.cardText}>
                          Intuitive cloud-based door access control software for 100% serverless, fully remote management on any device, anywhere in the world.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className={styles.avCard}>
                      <Card.Body className={styles.cardBody}>
                        <Card.Title className={styles.cardTitle}>Future-proof protection</Card.Title>
                        <Card.Text className={styles.cardText}>
                          99.9% unlock reliability with patented Triple Unlock access control technology. Supports key cards, fobs, PINs, smart devices and digital guest passes.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className={styles.avCard}>
                      <Card.Body className={styles.cardBody}>
                        <Card.Title className={styles.cardTitle}>Enhance your operational awareness</Card.Title>
                        <Card.Text className={styles.cardText}>
                          The proactive system features automatic updates, instant alerts and an open platform to help you stay ahead of the latest security threats.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </main>
  );
};

export default VideoSurv;
