import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import styles from "./ecosystem.module.css";
import EcosystemNav from "./EcosystemNav";

function DetectInfo() {
    const cardData = [
        {
            imgSrc: "/assets/eco/dome-cam.jpeg",
            alt: "Dome ceiling mounted surveillance camera",
            title: "Video Surveillance",
            text: "Using Avigilon's video surveillance cameras offers 24/7 protection",
            link: "/video-surveillance" 
        },
        {
            imgSrc: "/assets/eco/halo-2.webp",
            alt: "HALO smart sensor",
            title: "HALO Sensor",
            text: "Alerts when vape, smoke, chemicals or abnormal noises are in the area",
            link: ""
        },
        {
            imgSrc: "/assets/eco/body-wornCam.png",
            alt: "Body-worn camera",
            title: "Body-Worn Cameras",
            text: "Record all of your interactions from a first-person perspective",
            link: "/body-cameras"
        },
        {
            imgSrc: "/assets/eco/cwd.png",
            alt: "Concealed weapons detection system",
            title: "Concealed Weapons Detection",
            text: "This touchless system allows visitors to walk through without emptying pockets or bags",
            link: ""
        },
        {
            imgSrc: "/assets/eco/access-control.webp",
            alt: "Access control phone app unlocking door",
            title: "Avigilon Access Control System (ACM)",
            text: "Monitor when and where people are at all times",
            link: "/video-surveillance#access"
        },
        {
            imgSrc: "/assets/eco/avig-lpr.avif",
            alt: "Software linking license plate to information on a computer screen",
            title: "License Plate Recognition",
            text: "Search and quickly find license plate captures for verification and investigation",
            link: "/public-safety#lpr" 
        },
    ];

    return (
        <div className={styles.ecosystem} role="main">
            <div
                className={styles.splashImage}
                style={{ backgroundImage: `url('/assets/background/library-cam.jpeg')` }}
                aria-label="Background image showing surveillance technology"
                role="img"
            />
            <EcosystemNav />
            <h1>Motorola Solutions Ecosystem <br/>Detection Technologies</h1>
            <Container className="my-4">
                <Card className={`${styles.ecoCard} mb-4`} role="region" aria-labelledby="intro-card">
                    <Card.Body>
                        <Card.Title className={styles.cardTitle}>
                            Know what’s happening
                        </Card.Title>
                        <Card.Text>
                            View across vast areas, identify anomalies and threats, secure entry points
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Row className="gx-4">
                    {cardData.map((card, index) => (
                        <Col key={index} lg={3} md={4} sm={6} xs={12} className="mb-4">
                            <Link to={card.link} className="text-decoration-none" aria-label={`Learn more about ${card.title}`}>
                                <Card className={`${styles.productCard} h-100`} role="region" aria-labelledby={`card-title-${index}`}>
                                    <Card.Img
                                        variant="top"
                                        src={card.imgSrc}
                                        alt={card.alt}
                                        className={styles.productImg}
                                    />
                                    <Card.Body className={styles.cardBody}>
                                        <Card.Title className={styles.productTitle} id={`card-title-${index}`}>{card.title}</Card.Title>
                                        <Card.Text className={styles.productText}>{card.text}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
                <Row className="mt-4">
                    <Col lg={4} md={6} sm={12}>
                        <Card className={styles.ecoCard} role="region" aria-labelledby="wide-areas-card">
                            <Card.Body>
                                <Card.Title className={styles.cardTitle} id="wide-areas-card">
                                    View across vast areas
                                </Card.Title>
                                <Card.Text>
                                    Specialized fixed cameras can see through dark nights and inclement weather providing visibility across vast areas, augmented by built-in self-learning analytics that can learn their surroundings to identify anomalies and enable better awareness and protection 24/7.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <Card className={styles.ecoCard} role="region" aria-labelledby="anomalies-card">
                            <Card.Body>
                                <Card.Title className={styles.cardTitle} id="anomalies-card">
                                    Identify anomalies and threats
                                </Card.Title>
                                <Card.Text>
                                    AI-powered video analytics can detect banned individuals or license plates, brandished or concealed weapons, deliveries arriving on property, or unusual activity like loitering or driving in the wrong direction, sending detailed information to alert staff and inform essential actions.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <Card className={styles.ecoCard} role="region" aria-labelledby="secure-entry-card">
                            <Card.Body>
                                <Card.Title className={styles.cardTitle} id="secure-entry-card">
                                    Secure entry points
                                </Card.Title>
                                <Card.Text>
                                    Access control systems can secure entry points and automatically alert when doors are propped open or breached, complemented by video footage to see who’s on site and know what’s happening in real-time or in the past.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default DetectInfo;
