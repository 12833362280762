import React from 'react';
import styles from "./bodyWorn.module.css";
import { Card, Container, Row, Col } from 'react-bootstrap';
import ContactUsModal from '../ContactUsModal';

const BodyWorn = () => {
    const cards = [
        {
            img: '/assets/bodyWorn/v700.png',
            title: 'V700',
            description: 'Level up to the V700 body camera designed to keep you connected throughout your shift with its LTE capabilities and seamless technology integrations.'
        },
        {
            img: '/assets/bodyWorn/vb4001.png',
            title: 'VB400',
            description: 'Ideal for small to mid-size police agencies or security applications, the VB400\'s intuitive, rugged design makes it even easier to capture high-quality video evidence',
            bestSeller: true,
        }
    ];

    const lastTwoCards = [
        {
            img: '/assets/bodyWorn/vmel.png',
            title: 'VideoManager EL',
            description: 'Easily manage police body cameras and in-car video systems from the same screen, and prepare evidential footage, with our intuitive cloud-based platform'
        },
        {
            img: '/assets/bodyWorn/vmex.png',
            title: 'VideoManager EX',
            description: 'From schools to grocery stores, VideoManager EX makes it simple to set up, and use, your VB400 body cameras with minimal training and maintenance.'
        }
    ];

    return (
        <div className={styles.main} role="main">
            <div
                className={styles.splashImage}
                style={{ backgroundImage: `url('/assets/home/pcola-beach1.jpg')` }}
                role="img"
                aria-label="Background image of a beach"
            />
            <h1>Body-Worn Camera Solutions</h1>
            <p>A first-hand perspective when it matters most</p>
            <Container>
                <Row className="justify-content-center">
                    {cards.map((card, index) => (
                        <Col key={index} md={4} className="d-flex mb-4">
                            <Card className={`mb-4 ${styles.card}`} aria-labelledby={`card-title-${index}`}>
                                {card.bestSeller && (
                                    <div className={styles.ribbon} aria-label="Best Seller">
                                        <span>Best Seller</span>
                                    </div>
                                )}
                                <Card.Img 
                                    src={card.img} 
                                    className={styles['card-img-top']} 
                                    alt={`Image of ${card.title}`} 
                                />
                                <Card.Body className={styles['card-body']}>
                                    <Card.Title id={`card-title-${index}`} className={styles['card-title']}>
                                        {card.title}
                                    </Card.Title>
                                    <Card.Text className={styles['card-text']}>
                                        {card.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            <h2>Powerful hardware, made even better with software</h2>
            <p>
                Our software enables you to manage your body cameras, and the footage they capture, <br />
                in one easy-to-use user interface. So whether you're a police agency or small business,<br />
                you're only a page away from configuring a body camera or securely redacting footage.
            </p>
            <Container className="text-center">
                <Row>
                    {lastTwoCards.map((card, index) => (
                        <Col key={index} md={6} className="d-flex mb-4 mx-auto">
                            <Card className={`mb-4 ${styles.card}`} aria-labelledby={`last-card-title-${index}`}>
                                <Card.Img 
                                    src={card.img} 
                                    className={styles['card-img-top']} 
                                    alt={`Image of ${card.title}`} 
                                />
                                <Card.Body className={styles['card-body']}>
                                    <Card.Title id={`last-card-title-${index}`} className={styles['card-title']}>
                                        {card.title}
                                    </Card.Title>
                                    <Card.Text className={styles['card-text']}>
                                        {card.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            <ContactUsModal />
        </div>
    );
}

export default BodyWorn;
