import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import styles from './navbar.module.css'; // Import the CSS module

function NavBar() {
  const [expanded, setExpanded] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleScroll = () => {
    setScrolling(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header>
      <Navbar
        fixed="top"
        expanded={expanded}
        expand="lg"
        className={`${scrolling || expanded ? styles.navCustomBg : styles.navCustom} ${expanded ? styles.navbarExpanded : ''}`}
        onToggle={() => setExpanded(!expanded)}
        aria-label="Main navigation"
      >
        <Container>
          <LinkContainer to="/" onClick={() => setExpanded(false)}>
            <Navbar.Brand aria-label="Homepage">
              <img
                src="/images/cesLogo.svg"
                className={`${styles.logoContainer1} d-inline-block align-top`}
                alt="CES logo"
              />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle
            onClick={() => setExpanded(!expanded)}
            aria-controls="basic-navbar-nav"
            aria-expanded={expanded}
            className={expanded ? styles.navbarTogglerIconExpanded : styles.navbarTogglerIcon}
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className={`${scrolling || expanded ? styles.navbarCollapseBg : styles.navbarCollapse} ${expanded ? styles.navbarExpandedMenu : ''}`}
          >
            <Nav className={`ms-auto align-items-center ${styles.bgBodyTertiary}`} role="navigation" aria-label="Primary navigation">
              <LinkContainer to="/" onClick={() => setExpanded(false)}>
                <Nav.Link className={`${styles.customNavLink} ${expanded ? styles.customNavLinkExpanded : ''}`} aria-current="page">
                  Home
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/radios" onClick={() => setExpanded(false)}>
                <Nav.Link className={`${styles.customNavLink} ${expanded ? styles.customNavLinkExpanded : ''}`}>Radios</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/wave-ptx" onClick={() => setExpanded(false)}>
                <Nav.Link className={`${styles.customNavLink} ${expanded ? styles.customNavLinkExpanded : ''}`}>WAVE PTX</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/video-surveillance" onClick={() => setExpanded(false)}>
                <Nav.Link className={`${styles.customNavLink} ${expanded ? styles.customNavLinkExpanded : ''}`}>Video Surveillance</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/rental-radios" onClick={() => setExpanded(false)}>
                <Nav.Link className={`${styles.customNavLink} ${expanded ? styles.customNavLinkExpanded : ''}`}>Rentals</Nav.Link>
              </LinkContainer>
              <Dropdown
                onMouseEnter={() => setShowDropdown(true)}
                onMouseLeave={() => setShowDropdown(false)}
                show={showDropdown}
                className={styles.dropdownHover}
                aria-labelledby="solutions-dropdown"
              >
                <Dropdown.Toggle
                  as="a"
                  className={`${styles.customNavLink} ${styles.dropdownToggle} ${expanded ? styles.customNavLinkExpanded : ''}`}
                  aria-expanded={showDropdown}
                >
                  Solutions
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className={styles.dropdownMenu}
                  aria-labelledby="solutions-dropdown"
                  role="menu"
                  aria-hidden={!showDropdown}
                  tabIndex={showDropdown ? 0 : -1} // Ensure focus management
                >
                  <Dropdown.Item 
                    as={Link} 
                    to="/public-safety" 
                    className={styles.dropdownItem} 
                    onClick={() => setExpanded(false)}
                    role="menuitem"
                    aria-label="Public Safety Solutions"
                  >
                    Public Safety
                  </Dropdown.Item>
                  <Dropdown.Item 
                    as={Link} 
                    to="/radios/bda" 
                    className={styles.dropdownItem} 
                    onClick={() => setExpanded(false)}
                    role="menuitem"
                    aria-label="In-Building Coverage Solutions"
                  >
                    In-Building Coverage
                  </Dropdown.Item>
                  <Dropdown.Item 
                    as={Link} 
                    to="/body-cameras" 
                    className={styles.dropdownItem} 
                    onClick={() => setExpanded(false)}
                    role="menuitem"
                    aria-label="Body-Worn Cameras Solutions"
                  >
                    Body-Worn Cameras
                  </Dropdown.Item>
                  <Dropdown.Item 
                    as={Link} 
                    to="/ecosystem-detect" 
                    className={styles.dropdownItem} 
                    onClick={() => setExpanded(false)}
                    role="menuitem"
                    aria-label="Ecosystem Solutions"
                  >
                    Ecosystem
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <LinkContainer to="/about" onClick={() => setExpanded(false)}>
                <Nav.Link className={`${styles.customNavLink} ${expanded ? styles.customNavLinkExpanded : ''}`}>About Us</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contact-us" onClick={() => setExpanded(false)}>
                <Nav.Link className={`${styles.customNavLink} ${expanded ? styles.customNavLinkExpanded : ''}`}>Contact</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default NavBar;
