import { Card, Container } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import "../../styles.css";
import PartnerCarousel from "./PartnerCarousel";
import Ecosystem from "./Ecosystem";
import { LinkContainer } from "react-router-bootstrap";
import ContactUsModal from "../ContactUsModal";

function Home() {
  
  return (
    <div className="home">
      <header className="splash-image" style={{ backgroundImage: `url('/assets/home/pcola-beach1.jpg')` }}>
        <div className="splash-content">
          <Container>
            <h1>When You Need Communications in an Instant</h1>
          </Container>
        </div>
      </header>

      <main className="home-solutions">
        <h2>MISSION CRITICAL SOLUTIONS</h2>
        <Container>
          <Row className="home-row">
            <Col xs={12} md={4} className="d-flex justify-content-center">
              <LinkContainer to="/radios" aria-label="2-Way Radios">
                <Card className="home-card" role="region" aria-labelledby="card-title-radio">
                  <Card.Img variant="top" src="/assets/home/2-wayRadio.jpeg" alt="Motorola Solution 2-way Radio" />
                  <Card.Body>
                    <Card.Title id="card-title-radio">2-Way Radios</Card.Title>
                    <p>Offering the very best of 2-Way radios thanks to Motorola Solutions</p>
                    <button className="home-card-button">Learn More</button>
                  </Card.Body>
                </Card>
              </LinkContainer>
            </Col>

            <Col xs={12} md={4} className="d-flex justify-content-center">
              <LinkContainer to="/video-surveillance" aria-label="Video Surveillance">
                <Card className="home-card" role="region" aria-labelledby="card-title-surveillance">
                  <Card.Img loading="lazy" variant="top" src="/assets/home/avi-ptz.jpeg" alt="Mounted surveillance camera" />
                  <Card.Body>
                    <Card.Title id="card-title-surveillance">Video Surveillance</Card.Title>
                    <p>With the help of Avigilon, any and all of your camera needs can be solved</p>
                    <button className="home-card-button">Learn More</button>
                  </Card.Body>
                </Card>
              </LinkContainer>
            </Col>

            <Col xs={12} md={4} className="d-flex justify-content-center">
              <LinkContainer to="/body-cameras" aria-label="Body-Worn Cameras">
                <Card className="home-card" role="region" aria-labelledby="card-title-body-cameras">
                  <Card.Img variant="top" src="/assets/home/body-wornCam.jpeg" alt="Security wearing a body-worn camera" />
                  <Card.Body>
                    <Card.Title id="card-title-body-cameras">Body-Worn Cameras</Card.Title>
                    <p>Attention! All public-facing roles, we can help ease some of your stress.</p>
                    <button className="home-card-button">Learn More</button>
                  </Card.Body>
                </Card>
              </LinkContainer>
            </Col>
          </Row>

          <Row className="home-row">
            <Col xs={12} md={4} className="d-flex justify-content-center">
              <LinkContainer to="wave-ptx" aria-label="Wave PTX">
                <Card className="home-card" role="region" aria-labelledby="card-title-wave-ptx">
                  <Card.Img variant="top" src="/assets/home/wave.png" alt="Wave PTX can connect devices all over the US" />
                  <Card.Body>
                    <Card.Title id="card-title-wave-ptx">Wave PTX</Card.Title>
                    <p>Nationwide Coverage! Talk with all connected devices across the US at the push of a button</p>
                    <button className="home-card-button">Learn More</button>
                  </Card.Body>
                </Card>
              </LinkContainer>
            </Col>

            <Col xs={12} md={4} className="d-flex justify-content-center">
              <LinkContainer to="/radios/bda" aria-label="In-Building Coverage (BDA)">
                <Card className="home-card" role="region" aria-labelledby="card-title-bda">
                  <Card.Img variant="top" src="/assets/bda/bda-das.jpg" alt="BDA building diagram" />
                  <Card.Body>
                    <Card.Title id="card-title-bda">In-Building Coverage (BDA)</Card.Title>
                    <p>We can help you improve your building coverage and eliminate any deadzones.</p>
                    <button className="home-card-button">Learn More</button>
                  </Card.Body>
                </Card>
              </LinkContainer>
            </Col>

            <Col xs={12} md={4} className="d-flex justify-content-center">
              <LinkContainer to="/public-safety" aria-label="Public Safety">
                <Card className="home-card" role="region" aria-labelledby="card-title-public-safety">
                  <Card.Img variant="top" src="/assets/home/sherrif2.png" alt="Sheriff's deputy with mobile Motorola radio on chest" />
                  <Card.Body>
                    <Card.Title id="card-title-public-safety">Public Safety</Card.Title>
                    <p>Helping public safety with all their communication needs since 1981</p>
                    <button className="home-card-button">Learn More</button>
                  </Card.Body>
                </Card>
              </LinkContainer>
            </Col>
          </Row>
        </Container>
      </main>

      <section className="ecosystem">
        <h3>The Motorola Solutions ECOSYSTEM</h3>
        <Ecosystem />
      </section>

      <ContactUsModal />
      
      <section className="partners">
        <h4>OUR PARTNERS</h4>
        <PartnerCarousel />
      </section>
    </div>
  );
}

export default Home;
