import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import EmblaCarousel from './EmblaCarousel';
import styles from './radioDetail.module.css';
import { RadioData } from './RadioData';
import { FaFilePdf } from 'react-icons/fa6';

const RadioDetail = () => {
  const { name } = useParams();
  const radio = RadioData.find(item => item.name === name);

  if (!radio) {
    return <div>Radio not found</div>;
  }

  return (
    <div className={styles.main} role="main">
      <div
        className={styles.splashImage}
        style={{ backgroundImage: `url('/assets/home/pcola-beach1.jpg')` }}
        role="img"
        aria-label="Background image of a beach"
      />

      <Container className={styles.detailContainer}>
        <h1 className={styles.title}>{radio.title}</h1>
        <Row>
          <Col md={6} className={styles.carouselCol}>
            <EmblaCarousel
              slides={radio.images}
              options={{ loop: true }}
              aria-label={`Image carousel for ${radio.title}`}
            />
          </Col>
          <Col md={6} className={styles.infoCol}>
            <p className={styles.description}>{radio.description}</p>
            <h2>Features</h2>
            <ul className={styles.features}>
              {radio.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <a 
              href={radio.specs} 
              target="_blank" 
              rel="noopener noreferrer" 
              className={styles.specsLink}
              aria-label={`View full specifications for ${radio.title}`}
            >
              <FaFilePdf aria-hidden="true" /> View Full Specifications
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RadioDetail;
