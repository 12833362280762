// components/icons/MyIcon4.js

import React from 'react';

const DetectorIcon= ({ className }) => (
  <svg
    id="concealed-weapon-detection-64-b"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    className={className}
  >
    <g>
      <g>
        <g>
          <path
            d="M32,28.68c-2,0-3.59-2.29-3.59-4.41V23.15a3.59,3.59,0,1,1,7.17,0v1.12c0,2.15-1.62,4.41-3.58,4.41"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
            }}
          />
          <path
            d="M33.36,28.36l4.2,1.7a2.84,2.84,0,0,1,1.76,2.68L39.44,35v8.14l-3,.81-.74,9.78H28.3l-.74-9.78-3-.81V35l.13-2.23a2.85,2.85,0,0,1,1.76-2.68l4.21-1.7"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
            }}
          />
        </g>
        <line
          x1="48.23"
          y1="53.66"
          x2="48.23"
          y2="10.3"
          style={{
            fill: 'none',
            stroke: 'currentColor',
            strokeMiterlimit: 10,
            strokeWidth: '2px',
          }}
        />
        <line
          x1="45.2"
          y1="53.66"
          x2="51.26"
          y2="53.66"
          style={{
            fill: 'none',
            stroke: 'currentColor',
            strokeMiterlimit: 10,
            strokeWidth: '2px',
          }}
        />
        <line
          x1="15.29"
          y1="53.66"
          x2="15.29"
          y2="10.3"
          style={{
            fill: 'none',
            stroke: 'currentColor',
            strokeMiterlimit: 10,
            strokeWidth: '2px',
          }}
        />
        <line
          x1="12.26"
          y1="53.66"
          x2="18.32"
          y2="53.66"
          style={{
            fill: 'none',
            stroke: 'currentColor',
            strokeMiterlimit: 10,
            strokeWidth: '2px',
          }}
        />
        <line
          x1="17.25"
          y1="11.82"
          x2="17.25"
          y2="14.85"
          style={{
            fill: 'none',
            stroke: 'currentColor',
            strokeMiterlimit: 10,
            strokeWidth: '2px',
          }}
        />
        <line
          x1="46.29"
          y1="11.82"
          x2="46.29"
          y2="14.85"
          style={{
            fill: 'none',
            stroke: 'currentColor',
            strokeMiterlimit: 10,
            strokeWidth: '2px',
          }}
        />
        <g>
          <line
            x1="51.42"
            y1="42.54"
            x2="54.99"
            y2="44.89"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
            }}
          />
          <line
            x1="51.42"
            y1="21.42"
            x2="54.99"
            y2="19.08"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
            }}
          />
          <line
            x1="53.49"
            y1="31.98"
            x2="58.02"
            y2="31.98"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
            }}
          />
        </g>
        <g>
          <line
            x1="12.58"
            y1="21.42"
            x2="9.01"
            y2="19.08"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
            }}
          />
          <line
            x1="12.58"
            y1="42.54"
            x2="9.01"
            y2="44.89"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
            }}
          />
          <line
            x1="10.51"
            y1="31.98"
            x2="5.98"
            y2="31.98"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeMiterlimit: 10,
              strokeWidth: '2px',
            }}
          />
        </g>
      </g>
      <rect width="64" height="64" style={{ fill: 'none' }} />
    </g>
  </svg>
);

export default DetectorIcon;
