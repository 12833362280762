import React from 'react';

const PlateIcon = ({ className }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    className={className}
  >
    <rect width="64" height="64" style={{ fill: 'none', opacity: 0 }} />
    <g>
      <rect
        x="10.18"
        y="18.56"
        width="43.82"
        height="26.04"
        rx="4.51"
        ry="4.51"
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeLinecap: 'square',
          strokeMiterlimit: 10,
          strokeWidth: '2px',
        }}
      />
      <circle
        cx="14.49"
        cy="21.9"
        r="1.08"
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeLinecap: 'square',
          strokeMiterlimit: 10,
          strokeWidth: '1.08px',
        }}
      />
      <circle
        cx="50.06"
        cy="21.9"
        r="1.08"
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeLinecap: 'square',
          strokeMiterlimit: 10,
          strokeWidth: '1.08px',
        }}
      />
      <circle
        cx="14.49"
        cy="40.88"
        r="1.08"
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeLinecap: 'square',
          strokeMiterlimit: 10,
          strokeWidth: '1.08px',
        }}
      />
      <circle
        cx="50.06"
        cy="40.88"
        r="1.08"
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeLinecap: 'square',
          strokeMiterlimit: 10,
          strokeWidth: '1.08px',
        }}
      />
      <g>
        <path
          d="M14.57,34.8l1.99-7.2h1.98l1.95,7.2h-1.58l-.4-1.59h-2.05l-.41,1.59h-1.48Zm2.9-5.81h-.02l-.69,3.11h1.44l-.73-3.11Z"
          style={{ fill: 'none', stroke: 'currentColor', strokeLinecap: 'square', strokeMiterlimit: 10, strokeWidth: '1.2px' }}
        />
        <path
          d="M21.33,34.8v-7.2h2.67c1.15,0,2.09,.33,2.09,1.86,0,.83-.41,1.43-1.23,1.58v.02c1.07,.15,1.35,.84,1.35,1.82,0,1.82-1.59,1.9-2.22,1.9h-2.66Zm1.5-4.26h1.01c.33,0,.79-.22,.79-.92,0-.53-.36-.92-.87-.92h-.93v1.83Zm0,3.15h.84c.32,0,1.04-.01,1.04-1.02,0-.64-.24-1.03-1-1.03h-.88v2.04Z"
          style={{ fill: 'none', stroke: 'currentColor', strokeLinecap: 'square', strokeMiterlimit: 10, strokeWidth: '1.2px' }}
        />
        <path
          d="M27.43,31.2c0-1.9,0-3.72,2.48-3.72,1.53,0,2.15,.85,2.09,2.39h-1.48c0-.96-.17-1.32-.62-1.32-.85,0-.96,.8-.96,2.64s.11,2.64,.96,2.64c.7,0,.67-.9,.69-1.47h1.48c0,1.92-.77,2.54-2.17,2.54-2.48,0-2.48-1.83-2.48-3.72Z"
          style={{ fill: 'none', stroke: 'currentColor', strokeLinecap: 'square', strokeMiterlimit: 10, strokeWidth: '1.2px' }}
        />
        <path
          d="M35.4,31.6v1.02h-2.44v-1.02h2.44Z"
          style={{ fill: 'none', stroke: 'currentColor', strokeLinecap: 'square', strokeMiterlimit: 10, strokeWidth: '1.2px' }}
        />
        <path
          d="M36.43,28.92c.5-.28,.99-.61,1.35-1.08h1.34v6.96h-1.5v-5.35c-.36,.3-.77,.56-1.18,.78v-1.31Z"
          style={{ fill: 'none', stroke: 'currentColor', strokeLinecap: 'square', strokeMiterlimit: 10, strokeWidth: '1.2px' }}
        />
        <path
          d="M40.58,34.8v-1.23c2.19-2.92,2.65-3.26,2.65-4.13,0-.35-.13-.68-.53-.68-.58,0-.61,.55-.61,1h-1.41c-.1-1.3,.63-2.01,1.91-2.01,1.21,0,2.03,.49,2.03,1.79,0,1.56-1.82,3.34-2.58,4.18h2.65v1.08h-4.13Z"
          style={{ fill: 'none', stroke: 'currentColor', strokeLinecap: 'square', strokeMiterlimit: 10, strokeWidth: '1.2px' }}
        />
        <path
          d="M45.19,29.58c0-.72,.16-1.18,.48-1.46,.32-.27,.82-.37,1.51-.37,1.3,0,1.9,.53,1.9,1.85,0,.66-.19,1.44-.95,1.54v.02c.91,.17,1.06,.89,1.06,1.69,0,1.44-.77,2.02-2.17,2.02s-1.91-.49-1.91-1.97h1.41c0,.46-.02,.96,.59,.96,.64,0,.69-.59,.69-1.08,0-1.01-.33-1.07-1.31-1.07v-1.11c.92,.04,1.2-.06,1.2-1.03,0-.38-.06-.83-.54-.83-.51,0-.55,.41-.55,.81h-1.41Z"
          style={{ fill: 'none', stroke: 'currentColor', strokeLinecap: 'square', strokeMiterlimit: 10, strokeWidth: '1.2px' }}
        />
      </g>
      <line
        x1="10.9"
        y1="25.48"
        x2="53.55"
        y2="25.48"
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeLinecap: 'square',
          strokeMiterlimit: 10,
          strokeWidth: '2px',
        }}
      />
      <line
        x1="10.9"
        y1="37.12"
        x2="53.55"
        y2="37.12"
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeLinecap: 'square',
          strokeMiterlimit: 10,
          strokeWidth: '2px',
        }}
      />
      <line
        x1="19.21"
        y1="40.88"
        x2="45.14"
        y2="40.88"
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeLinecap: 'square',
          strokeMiterlimit: 10,
          strokeWidth: '2px',
        }}
      />
    </g>
  </svg>
);

export default PlateIcon;
