

import React from 'react';

const DomecamIcon = ({ className }) => (
  <svg
    id="a"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    className={className}
  >
    <path
      d="M38.25,35.02c0,3.41-2.8,6.18-6.25,6.18s-6.25-2.76-6.25-6.18,2.8-6.17,6.25-6.17,6.25,2.76,6.25,6.17Zm.48,8.08v-6.7c0-6.11-3.25-7.55-6.76-7.55h.06c-3.51,0-6.76,1.44-6.76,7.55v6.7m-9.62-14.25c.09,8.93,7.37,16.15,16.35,16.15s16.25-7.22,16.35-16.15m-13.94,6.17c0,1.31-1.08,2.38-2.4,2.38s-2.4-1.06-2.4-2.38,1.08-2.37,2.4-2.37,2.4,1.06,2.4,2.37Zm-.48-12.82c0,1.05-.86,1.9-1.92,1.9s-1.92-.85-1.92-1.9,.86-1.9,1.92-1.9,1.92,.85,1.92,1.9M54.49,7.96c-1.99-.33-11.86-.96-22.49-.96h-.01c-10.63,0-20.5,.63-22.49,.96-1.8,.3-2.64,.74-2.49,2.99,.53,4.49,2.04,10.8,3.09,13.99,1.06,3.19,1.71,3.91,4.58,3.91H49.31c2.86,0,3.52-.72,4.58-3.91,1.06-3.19,2.56-9.5,3.09-13.99,.15-2.25-.69-2.69-2.49-2.99Z"
      style={{
        fill: 'none',
        stroke: 'currentColor', // Use currentColor to inherit text color
        strokeMiterlimit: 10,
        strokeWidth: '2px'
      }}
    />
  </svg>
);

export default DomecamIcon;
