import React from "react";
import styles from "./about.module.css";
import { Container } from "react-bootstrap";

const About = () => {
    return (
        <div role="main">
            <div
                className={styles.splashImage}
                style={{ backgroundImage: `url('/assets/home/pcola-beach1.jpg')` }}
                aria-label="A splash image showcasing a beach"
            ></div>
            <Container className={styles.about}>
                <header>
                    <h1>About Us</h1>
                </header>
                <section aria-labelledby="about-text">
                    <p id="about-text" className={styles.text}>
                        We are a Wireless Communications company providing solutions for Public Safety, Utilities, Government, Gaming, Industrial, Chemical, Manufacturing, and Commercial businesses in the areas of Two-Way radios, Wireless Networking, Rental Radios, and as a Motorola Certified Service Center.
                    </p>
                    <p className={styles.text}>
                        The foundation of our company is our ability to consult, design, sell, install, repair, and maintain all that we offer. With service locations in the Florida Panhandle, Lower Alabama, and Lower Mississippi, we stand behind what we sell. Your needs and your ways of doing business change so let us help you stay a step ahead with the latest in Wireless Technologies.
                    </p>
                </section>
            </Container>

            <section className={styles.channel}>
                <Container>
                    <h2>Motorola Solutions Channel Partner & Service Elite Specialist</h2>
                    <p className={styles.text}>
                        The Motorola Service Elite Specialist is the highest level of Motorola service relationship. Motorola Servicers who attain this level have demonstrated consistent capabilities in performance, personnel, and commitment to Motorola products and services.
                    </p>
                    <div className={styles.imageContainer}>
                        <img
                            src='/assets/about/msi-cp.png'
                            className={styles.chp}
                            alt="Motorola Solutions Channel Partner"
                        />
                        <img
                            src='/assets/about/ses-rev.png'
                            className={styles.ses}
                            alt="Motorola Solutions Service Elite Specialist"
                        />
                    </div>
                </Container>
            </section>

        
            {/* 
            <Container>
                <section aria-labelledby="history">
                    <h3 id="history">Our History</h3>
                    <p className={styles.text}>
                        The relationship with Motorola began in the early 1940’s when Don Livingston, Sr. was hired on with Motorola. At this time, Motorola was introducing the first FM 2-Way portable “Walkie Talkie.” Working his way up through commercial sales, he became an Executive Vice President of Motorola’s Communications & Electronics Division out of Dallas, Texas. His success in the 20-plus years with Motorola is attributed to his love of being around people and his genuine concern with making everyone he associated with successful.
                    </p>
                    <p className={styles.text}>
                        In 1962, his son Don Livingston Jr. was hired by Motorola. He spent 19 years with the Motorola C&E Division working his way up to area sales manager for the southeast. In 1981, he was given the opportunity to buy a small company named Communications Engineering Services (CES), including five employees, in Pensacola, Florida. He continued to maintain a relationship with Motorola as an authorized service center. He successfully expanded the business by using the same philosophy and hard work that made him successful with Motorola. Within seven years, the business had more than quadrupled, and a third generation of Livingston began their association with Motorola at CES, Inc. Since then, Jeff Livingston has helped expand the business from 20 employees to more than 100 employees over four states using the same philosophy as his father and grandfather -- “Building Relationships.”
                    </p>
                </section>
            </Container>
            */}
        </div>
    );
}

export default About;
