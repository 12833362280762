export const RadioData = [
    {
      id: 1,
      name: "sl300",
      mainImage: "/assets/radios2/radios/sl300/sl300-front.jpeg",
      images: [
        "/assets/radios2/radios/sl300/sl300-front.jpeg",
        "/assets/radios2/radios/sl300/sl300-left.jpeg",
        "/assets/radios2/radios/sl300/sl300-right.jpeg",
        "/assets/radios2/radios/sl300/sl300-side.jpeg",
        "/assets/radios2/radios/sl300/sl300-top.jpeg",
      ],
      title: "MOTOTRBO™ SL300",
      description: 'Measuring under an inch thick, the SL300 is ultra-portable with a stubby antenna,\n' +
        'curved edges and rugged frame. It features an Active View display with a matrix of LED lights\n' +
        'behind the radio housing and “Range Max” technology, an advanced radio design and patented antenna for \n' +
        'enhanced range. Designed for easy and intuitive use and outfitted with the latest technology, the SL300 is the perfect work partner',
        features:[
            "Channels: 2 (Plain Model) or 99 (Display Model)",
            "Power: 1-2W (VHF), 1-3W(UHF)",
            "Size: 4.95 x 2.17 x 0.87in",
            "weight: 5.96 oz",
            "Includes: Li-Ion battery, charger, antenna, clip",
            "Operates in both analog or digital modes"
        ],
      specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/business/products/two-way_radios/portable_radios/_documents/static_files/mototrbo_sl300_data_sheet.pdf',
      tags: ["Portable", "Commercial"],
      rating: 4.9,

    },
    {
        id: 2,
        name: "r7",
        mainImage: "/assets/radios2/radios/r7/r7-front.jpeg",
        images: [
           "/assets/radios2/radios/r7/r7-front.jpeg",
           "/assets/radios2/radios/r7/r7-noScreen.jpeg",
           "/assets/radios2/radios/r7/r7-left.jpeg",
           "/assets/radios2/radios/r7/r7-right.jpeg",
           "/assets/radios2/radios/r7/r7-side.jpeg",
           "/assets/radios2/radios/r7/r7-back.jpeg",
          ],
        title: "MOTOTRBO™ R7",
        description: 'MOTOTRBO™ R7 offers game-changing audio capabilities in a rugged, future-ready device. Its advanced audio processing ensures that your communications are loud and clear, while its rugged construction is ready for harsh environments, and advanced connectivity options get your workforce ready for tomorrow. ',
        features: [
            "Channels: 64",
            "Power: 1-5W (VHF), 1-4W(UHF)",
            "Size: 5.2 x 2.2 x 1.2in",
            "Weight: 10oz",
            "Included: Battery, Charger, Antenna, 2\" Belt Clip"
        ],
        specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/mototrbo/r7/mototrbo-r7_nam_datasheet_eng.pdf',
        tags: ["Portable","Professional"],
        rating: 5.0
      },
      {
        id: 3,
        name: "xpr3000e",
        mainImage: "/assets/radios2/radios/xpr3000e/xpr3000e-front.jpeg",
        images: [
            "/assets/radios2/radios/xpr3000e/xpr3000e-front.jpeg",
            "/assets/radios2/radios/xpr3000e/xpr3000e-left.jpeg",
            "/assets/radios2/radios/xpr3000e/xpr3000e-right.jpeg",
            "/assets/radios2/radios/xpr3000e/xpr3000e-back.jpeg",
            "/assets/radios2/radios/xpr3000e/xpr3000e-side.jpeg",
            "/assets/radios2/radios/xpr3000e/xpr3000e-top.jpeg",
          ],
        title: "XPR 3000e",
        description: 'With this dynamic evolution of MOTOTRBO™ digital two-way radios, you’re better connected, safer and more efficient. The XPR™ 3000e Series is designed for the everyday worker who needs effective communications. With systems support and loud, clear audio, these next-generation radios deliver cost-effective connectivity to your organization.',
        features: [
            "channels: 12 with display, 16 without display",
            "Power: 5W (VHF), 4W (UHF)",
            "Size: 4.8 x 2.2 x 1.64in",
            "Weight: 10oz",
            "Included: Battery, Charger, Antenna, Belt clip",
            "XPR 3500e: Monochrome display, limited keypad",
            "XPR 3300e: No display or keypad",
        ],
        specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/products/two-way-radios/mototrbo/portable-radios/xpr3000e/XPR3000e_DataSheet.pdf',
        tags: ["Portable", "Professional"]
      },
      {
        id: 4,
        name: "sl3500e",
        mainImage: "/assets/radios2/radios/sl3500e/sl3500e-front.jpeg",
        images: [
            "/assets/radios2/radios/sl3500e/sl3500e-front.jpeg",
            "/assets/radios2/radios/sl3500e/sl3500e-left.jpeg",
            "/assets/radios2/radios/sl3500e/sl3500e-right.jpeg",
            "/assets/radios2/radios/sl3500e/sl3500e-back.jpeg",
            "/assets/radios2/radios/sl3500e/sl3500e-side.jpeg",
            "/assets/radios2/radios/sl3500e/sl3500e-top.jpeg",
          ],
        title: "MOTOTRBO™ SL3500e",
        description: 'With its slim, lightweight design, the MOTOTRBO SL3500e portable two-way radio is stylish and discreet. It blends seamlessly with professional attire, allowing you to move freely. At the same time, it’s durable and built to last. Get quick access to key radio functions along with information at a glance. Connect discreetly to Bluetooth® earpieces and sensors. Access Wi-Fi networks for over-the-air software updates. And use the SL3500e on trunking systems that support both data and voice.',
        features: [
          "Channels: 128",
          "Power: 2-3W (UHF), (VHF)", 
           "Channels: 128",
           "Size: 5 x 2.2 x 1.1in",
           "Weight: 6.9 oz",
           "WiFi Optional",
        ],
        specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/products/two-way-radios/mototrbo/portable-radios/sl3500e/sl3500e_specifications_en_na.pdf',
        tags: ["Portable", "Professional"]
      },
      {
        id: 5,
        name: "xpr5000e",
        mainImage: "/assets/radios2/radios/xpr5000e/xpr5000e-screen-front.jpeg",
        images: [
            "/assets/radios2/radios/xpr5000e/xpr5000e-screen-front.jpeg",
            "/assets/radios2/radios/xpr5000e/xpr5000e-numdisplay-front.jpeg",
            "/assets/radios2/radios/xpr5000e/xpr5000e-left.jpeg",
            "/assets/radios2/radios/xpr5000e/xpr5000e-right.jpeg",
            "/assets/radios2/radios/xpr5000e/xpr5000e-back.jpeg",
          ],
        title: "XPR 5000e",
        description: 'XPR 5000e Series provides voice, data and advanced features for efficient operation for skilled professionals who refuse to compromise. XPR 5000e Series includes: XPR 5550e/XPR 5580e: Color display, VHF, UHF and 800/900 XPR 5350e/XPR 5380e: Numeric display, VHF, UHF and 800/900',
        features: [
            "XPR 5550e: Alphanumeric display",
            "Channels: 1000",
            "Power: 1-25W / 25-45W (VHF), 25-45W / 25-40W / 1-40W (UHF)",
            "XPR 5350e: Numeric display",
            "Channels: 32",
            "Power: 1-25W / 25-45W (VHF), 1-25W / 25-40W / 1-40W (UHF)",
            "Size: 2.1 x 6.9 x 8.1in",
            "Weight: 3.9 lbs",
            "Included: mic, Bracket, Cable, Replacement button",

        ],
        specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/products/two-way-radios/mototrbo/mobile-radios/xpr5000e/XPR5000e_DataSheet.pdf',
        tags: ["Mobile", "Professional" , ],
        rating: 5.0
      },
      {
        id: 6,
        name: "r2",
        mainImage: "/assets/radios2/radios/r2/r2-front.jpeg",
        images: [
            "/assets/radios2/radios/r2/r2-front.jpeg",
            "/assets/radios2/radios/r2/r2-left.jpeg",
            "/assets/radios2/radios/r2/r2-right.jpeg",
            "/assets/radios2/radios/r2/r2-back.jpeg",
            "/assets/radios2/radios/r2/r2-side.jpeg",
            "/assets/radios2/radios/r2/r2-bottom.jpeg",
          ],
        title: "MOTOTRBO™ R2",
        description: 'A next-level workhorse, the MOTOTRBO R2 marries durability and ergonomics to ensure confident, easy handling. And with superior range, configurable audio and seamless integration, the R2 is a reliable addition to an uninterrupted workday.',
        features: [
            "Channels: 64",
            "Power: 1-5W (VHF), 1-4W (UHF)",
            "Size: 4.9 x 2.1 x 1.44in",
            "Weight: 10 oz",
            "Inluded: Battery, Charger, Belt clip"
        ],
        specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/products/two-way-radios/mototrbo_r2_datasheet_nam.pdf',
        tags: ["Portable", "Commercial"],
        rating: 5.0,
      }, 
      {
        id: 7,
        name: "bpr40",
        mainImage: "/assets/radios2/radios/bpr40/bpr40-front.jpg",
        images: [
            "/assets/radios2/radios/bpr40/bpr40-front.jpg",
            "/assets/radios2/radios/bpr40/bpr40-back.jpg",
            "/assets/radios2/radios/bpr40/bpr40-side.jpg",
            "/assets/radios2/radios/bpr40/bpr40-top.jpg",
          ],
        title: "BPR™40",
        description: 'The BPR™40 portable two-way radio delivers reliable, intuitive communication through 8 channels and 2 programmable buttons. The high-/low-power option saves battery charge. Features such as nuisance channel delete and repeater talkaround make the radio a versatile, high-performance device. The BPR40 is part of the Mag One™ by Motorola radio portfolio. This affordable solution gives remarkable quality and value that is perfect for your business, and comes with a complete assortment of accessories to customize your radio',
        features: [
            "Channels: 8",
            "Power: 5W (VHF), 4W (UHF)",
            "Size: 4.21 x 2.28 x 1.46in",
            "Weight: 12.3 oz",
            "included: Battery, Charger, Antenna, Spring belt"
        ],
        specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/business/products/two-way_radios/portable_radios/small_business_portable_radios/bpr40/_documents/static_files/mag_one_bpr40_spec_sheet.pdf',
        tags: ["Portable", "Commercial"]
      },
      {
        id: 8,
        name: "cp100d",
        mainImage: "/assets/radios2/radios/cp100d/cp100d-front.jpeg",
        images: [
            "/assets/radios2/radios/cp100d/cp100d-front.jpeg",
            "/assets/radios2/radios/cp100d/cp100d-right.jpeg",
            "/assets/radios2/radios/cp100d/cp100d-back.jpeg",
            "/assets/radios2/radios/cp100d/cp100d-side.jpeg",
            "/assets/radios2/radios/cp100d/cp100d-top.jpeg",
            "/assets/radios2/radios/cp100d/cp100d-NKP.jpeg",
            "/assets/radios2/radios/cp100d/cp100d-LKP.jpeg",
          ],
        title: "CP100D",
        description: 'Simple and effective for the light user who values ease and efficiency. Easy to use and practical, the CP100d offers analog voice communications, and MOTOTRBO digital when you are ready — for a straightforward path to digital migration. ',
        features: [
            "Options: Non keypad, Limited keypad, Full keypad",
            "Channels: 16 w/o keypad, 160 with keypad",
            "Power: 1-5W (VHF), 1-4W (UHF)",
            "Size: 4.7 x 2.2 x 1.4in",
            "Weight: 10.4 oz",
        ],
        specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/products/two-way-radios/mototrbo/cp100d_series_datasheet.pdf',
        tags: ["Portable", "Commercial"]
      },
      {
        id: 9,
        name: "evxs24",
        mainImage: "/assets/radios2/radios/evxs24/evxs24-front.jpeg",
        images: [
            "/assets/radios2/radios/evxs24/evxs24-yellow.jpeg",
            "/assets/radios2/radios/evxs24/evxs24-front.jpeg",
            "/assets/radios2/radios/evxs24/evxs24-right.jpg",
            "/assets/radios2/radios/evxs24/evxs24-back.jpg",
            "/assets/radios2/radios/evxs24/evxs24-side.jpg", 
          ],
        title: "EVX-S24",
        description: 'Give your team members a compact, discreet, and lightweight radio that allows them to be comfortable in any situation. Worn on a belt, tucked away in a pocket or clipped to a lapel, the water-submersible and dust-proof EVX-S24 is designed to survive demanding conditions. It operates in both analog and digital modes, so it is ideal for use with mixed fleets. And in digital mode, enjoy noise cancellation for crisp audio and clear conversations',
        features: [
            "Options: Black, Yellow",
            "Channels: 256 / 16 groups",
            "Power: 3W Digital (UHF), 2W Analog (UHF)",
            "Size: 3.58 x 2.17 x 1.24in",
            "Weight: 7.6 oz",
            "Included: Battery, Charger, Antenna, Clip",
        ],
        specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/vertex-standard/evxs24_series_sheet_hires_final_9_27_2017.pdf',
        tags: ["Portable", "Commercial"]
      },
    
      {
        id: 10,
        name: "tlk110",
        mainImage: "/assets/radios2/radios/tlk110/tlk110-front.jpeg",
        images: [
            "/assets/radios2/radios/tlk110/tlk110-front.jpeg",
            "/assets/radios2/radios/tlk110/tlk110-right.jpeg",
            "/assets/radios2/radios/tlk110/tlk110-left.jpeg",
            "/assets/radios2/radios/tlk110/tlk110-side.jpeg",
            "/assets/radios2/radios/tlk110/tlk110-back.jpeg",
            "/assets/radios2/radios/tlk110/tlk110-top.jpeg",  
          ],
        title: "TLK 110",
        description: 'The TLK 110 combines the flexibility of push-to-talk communication via LTE with the reliability of a rugged, purpose-built radio. With its effortless device management capabilities, the TLK 110 can be optimized for seamless operations, leaving your business safer, smarter and better connected. ',
        features: [
            "Channels: 96",
            "Network: 3G, 4G",
            "Nationwide Push-to-Talk",
            "Use with Motorola Soultions WAVE Software",
            "FREE with 30-month Service Subscription",
            "Size: 5.3 x 2.3 x 1.0in",
            "Weight: 6.95 oz",
        ],
        specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/tlk-110-na-datasheet.pdf',
        tags: ["Portable", "Commercial", "LTE"],
        rating: 4.8,
      },
      {
        id: 11,
        name: "tlk100",
        mainImage: "/assets/radios2/radios/tlk100/tlk100-front.jpeg",
        images: [
            "/assets/radios2/radios/tlk100/tlk100-front.jpeg",
            "/assets/radios2/radios/tlk100/tlk100-right.jpeg",
            "/assets/radios2/radios/tlk100/tlk100-side.jpeg",
            "/assets/radios2/radios/tlk100/tlk100-top.jpeg",  
          ],
        title: "TLK 100",
        description: 'Keep employees connected and accountable with crisp, clear audio, location tracking and Wi-Fi. Increase efficiency with single-button operations and long battery life. And improve focus without a distracting screen to get in the way. Forget building out or maintaining a costly radio infrastructure. Get your team up and running quickly by deploying nationwide push-to-talk in less than 24 hours. You can even use the TLK 100 two-way radio with your current compatible two-way radio systems and smartphones. There’s no costly or time-consuming FCC or spectrum licensing, or manual programming required. As an affordable monthly investment, you can scale your service to match your business needs. And you can reduce or stop your service easily, at any time. ',
        features: [
            "Channels: 8 (expandable to 96)",
            "Size: 5.17 x 2.31 x 0.87 in ",
            "Weight: 5.86 oz",
            "Wide-Area Communications Over Cellular Network",
            "Nationwide Push-to-Talk",
            "Use with Motorola Solutions WAVE Software",
            "FREE with 30-month Service Subscription",
        ],
        specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/products/two-way-radios/tlk100/tlk_100_two-way_radio_specification_sheet.pdf',
        tags: ["Portable", "Commercial", "LTE"],
        rating: 5.0
      },
      {
        id: 12,
        name: "tlk25",
        mainImage: "/assets/radios2/radios/tlk25/tlk25-front.jpeg",
        images: [
            "/assets/radios2/radios/tlk25/tlk25-front.jpeg",
            "/assets/radios2/radios/tlk25/tlk25-left.jpeg",
            "/assets/radios2/radios/tlk25/tlk25-right.jpeg", 
            "/assets/radios2/radios/tlk25/tlk25-left2.jpeg",
            "/assets/radios2/radios/tlk25/tlk25-side.jpeg",
          ],
        title: "TLK 25",
        features:[
            "Channels: 96",
            "Network: 2.4, 5 GHz Wi-Fi",
            "Size: 3.2 x 1.9 x 0.7 in",
            "Weight: 2.6 oz",
            "Use with Motorola Solution WAVE Software",
            "Free with 30-month Service Subscription",
        ],
        description: 'Compact, powerful, and equipped with an intuitive voice assistant for seamless operations, the WAVE PTX TLK 25 is designed for efficiency and safety. Access a communications and management ecosystem on a nationwide broadband network.',
        specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/products/tlk-25/wi-fi/data_sheet.pdf',
        tags: ["Portable", "Commercial", "LTE"]
      },
      {
        id: 13,
        name: "xpr2500",
        mainImage: "/assets/radios2/radios/xpr2500/xpr2500-front.jpeg",
        images: [
          "/assets/radios2/radios/xpr2500/xpr2500-front.jpeg",
          "/assets/radios2/radios/xpr2500/xpr2500-left.jpeg",
          "/assets/radios2/radios/xpr2500/xpr2500-right.jpeg",
          "/assets/radios2/radios/xpr2500/xpr2500-back.jpeg",
        ],
        title: "Motorola XPR2500",
        description: 'Delivering the best in digital radio technology, with features like integrated voice\n' +
          'systems capability, twice the calling capacity, and clearer voice communications, the XPR 2500 is\n' +
          'versatile and powerful. For affordable digital voice systems capability, the Motorola XPR-2500 gets the job done with clarity and ease.',
          features: [
              "Channels: 128",
              "Power: 1-45W, 25-45W (VHF) / 1-25W, 25-40W (UHF)",
              "Size: 1.73 x 6.67 x 4.64in",
              "Weight: 2.25 lbs",
              "Included: microphone, bracket, power cable"
          ],
          specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/business/product_lines/mototrbo/mobiles/xpr2500/_documents/_staticfiles/xpr2000series_product_spec_sheet.pdf',
        tags: ["Mobile", "Professional"],
        rating: 2.1
      },
      {
        id: 14,
        name: "tlk150",
        mainImage: "/assets/radios2/radios/tlk150/tlk150-front.jpeg",
        images: [
            "/assets/radios2/radios/tlk150/tlk150-front.jpeg",
            "/assets/radios2/radios/tlk150/tlk150-left.jpeg",
            "/assets/radios2/radios/tlk150/tlk150-right.jpeg", 
            "/assets/radios2/radios/tlk150/tlk150-back.jpeg",
            "/assets/radios2/radios/tlk150/tlk150-top.jpeg",
          ],
        title: "WAVE PTX TLK 150",
        description: 'Combine the broad coverage of a nationwide cellular network with the ease of two-way communications. Safely operate while driving via one push-to-talk button. Increase coverage, connections and productivity without expanding infrastructure.',
        features: [
            "Channels: 96",
            "Network: 4G LTE, 3G",
            "Size:  6.65 in x 1.73 in x 3.66 in",
            "Weight: 1.41 lbs",
            "Nationwide Push-to-Talk",
            "Use with Motorola Soultions WAVE Software"
        ],
        specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/occ/tlk_150_datasheet.pdf',
        tags: ["Mobile", "Commercial", "LTE"]
      },
      {
        id: 15,
        name: "ion",
        mainImage: "/assets/radios2/radios/ion/ion-front.jpeg",
        images: [
            "/assets/radios2/radios/ion/ion-front.jpeg",
            "/assets/radios2/radios/ion/ion-right.jpeg",
            "/assets/radios2/radios/ion/ion-left.jpeg",
            "/assets/radios2/radios/ion/ion-side.jpeg",
            "/assets/radios2/radios/ion/ion-back.jpeg",
            "/assets/radios2/radios/ion/ion-top.jpeg",  
          ],
        title: "MOTOTRBO™ ION Smart Radio",
        description: 'Your business runs on voice and data. But if your devices can only access one, you’re not running at your best. The rugged MOTOTRBO™ Ion smart radio is the first business-ready communication device with all-on voice and broadband data capabilities',
        features: [
            "Channels: 1000",
            "Power: 1-4W (UHF)",
            "Size: 5.6 x 2.8 x 1.2in",
            "Weight: 15 oz",
            "Included: Battery, Charger, Antenna, Holster",
            "Use with Motorola Solutions WAVE Software"
        ],
        specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/products/two-way-radios/mototrbo/portable-radios/mototrbo-ion-radio/MOTOTRBO_Ion_DataSheet_NAM.pdf',
        tags: ["Portable", "Professional", "LTE"]
      },
      {
        id: 16,
        name: "waveptx-app",
        mainImage: "/assets/radios2/radios/app/wave-app.jpg",
        images: [
            "/assets/radios2/radios/app/wave-app.jpg",
            "/assets/radios2/radios/app/ptxApp.png",
          ],
        title: "WAVE PTX App",
        description: 'Take Push-To-Talk Further: Based on the 3rd Generation Partnership Project (3GPP) Mission Critical Push-To-Talk (MCPTT) standards, WAVE PTT provides fast, secure, reliable broadband PTT communication across any network, any device. Expand existing land mobile radio (LMR) service to include broadband users on the devices and networks they already have. Extend two-way radio push-to-talk communication to phones, tablets and PCs and increase team productivity with instant communication anywhere you have a network or Wi-Fi connection.',
        features: [
            "Multimedia messaging",
            "Track your team on a map",
            "Share you location or set a meeting location",
            "Use with Motorola Soultions WAVE Software"
        ],
        specs: 'https://www.motorolasolutions.com/content/dam/msi/docs/EA_Collaterals/ENGLISH/wave/WAVE_PTX_Brochure_ENG.pdf',
        tags: [ "Commercial", "LTE"]
      },
     
  ];
  
  export default RadioData;
  