import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fixing default icon issue with react-leaflet and webpack
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

const LocationMap = () => {
  return (
    
    <MapContainer center={[30.428590, -88.245720]} zoom={7} style={{ height: "400px", width: "100%", border: "1px solid black", overflow: "hidden" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={[30.449010, -87.242110]}>
        <Popup>
          Pensacola, Florida <br /> 215 W Herman Ave, 32505
        </Popup>
      </Marker>
      <Marker position={[30.665180, -88.129870]}>
        <Popup>
          Mobile, Alabama <br /> 3360 Key St, 36609
        </Popup>
      </Marker>
      <Marker position={[30.366260, -88.546490]}>
        <Popup>
          Pascagoula, Mississippi <br /> 1777 Old Mobile Ave, 39567
        </Popup>
      </Marker>
     
    </MapContainer>
  );
};

export default LocationMap;
