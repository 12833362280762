import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import "../../styles.css";
import "swiper/css";
import 'swiper/css/autoplay';

const PartnerCarousel = () => {
  return (
    <div className='partner-swiper' role="region" aria-labelledby="carousel-title">
      
      <Swiper
        className='mySwiper'
        modules={[Autoplay, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        loopedSlides={3}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        pagination={{
          clickable: true,
          el: '.swiper-pagination',
        }}
      >
        <SwiperSlide className='swiper-slide'>
          <img src="/assets/partners/motorola-solutions.svg" alt="Motorola Solutions" />
        </SwiperSlide>
        <SwiperSlide className='swiper-slide'>
          <img src="/assets/partners/avigilon-logo.svg" alt="Avigilon" />
        </SwiperSlide>
        <SwiperSlide className='swiper-slide'>
          <img src="/assets/partners/avtec.png" alt="Avtec" />
        </SwiperSlide>
        <SwiperSlide className='swiper-slide'>
          <img src="/assets/partners/watchguard.png" alt="WatchGuard" />
        </SwiperSlide>
      </Swiper>
      <div className="swiper-pagination" aria-live="polite" />
    </div>
  );
};

export default PartnerCarousel;
