import React from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./ecosystem.module.css";
import EcosystemNav from "./EcosystemNav";

function CommunicateInfo() {
    const cardData = [
        {
            imgSrc: "/assets/eco/r7-hand.jpeg",
            alt: "MOTOTRBO™ R7 in hand",
            title: "MOTOTRBO™ Radios",
            text: "MOTOTRBO™ Radios offer the cutting edge in mobile 2-way radios",
            link: "/radios"
        },
        {
            imgSrc: "/assets/eco/waveptx.jpeg",
            alt: "WAVE PTX app being used on someone's phone",
            title: "WAVE Push-to-Talk",
            text: "Using WAVE PTX get 2-way radio coverage nationwide",
            link: "/wave-ptx"
        },
        {
            imgSrc: "/assets/eco/avtec-command.jpg",
            alt: "Avtec Command center",
            title: "Avtec",
            text: "Avtec offers everything you need when it comes to your command center",
            link: ""
        },
        {
            imgSrc: "/assets/eco/rave-alert.webp",
            alt: "computer software capable of sending mass alerts to phones",
            title: "Rave Mobile Safety",
            text: "Notify the masses of emergency with the click of a button",
            link: ""
        },
    ];

    return (
        <div className={styles.ecosystem} role="main">
            <div
                className={styles.splashImage}
                style={{ backgroundImage: `url('/assets/background/library-cam.jpeg')` }}
                role="img"
                aria-label="Background image showing communication technology"
            />
            <EcosystemNav />

            <h1>Motorola Solutions Ecosystem <br />Communication Technologies</h1>

            <Container className="my-4">
                <Card className={`${styles.ecoCard} mb-4`} role="region" aria-labelledby="intro-card">
                    <Card.Body>
                        <Card.Title className={styles.cardTitle} id="intro-card">
                            Connect and inform the right people
                        </Card.Title>
                        <Card.Text>
                            Broadcast mass notifications, centralize dispatch and media management, communicate across teams and technologies
                        </Card.Text>
                    </Card.Body>
                </Card>

                <Row className="gx-4">
                    {cardData.map((card, index) => (
                        <Col key={index} lg={3} md={4} sm={6} xs={12} className="mb-4">
                            <Link to={card.link} className={styles.cardLink} aria-label={card.title}>
                                <Card className={`${styles.productCard} h-100`} role="region" aria-labelledby={`card-title-${index}`}>
                                    <Card.Img
                                        variant="top"
                                        src={card.imgSrc}
                                        alt={card.alt}
                                        className={styles.productImg}
                                    />
                                    <Card.Body className={styles.cardBody}>
                                        <Card.Title className={styles.productTitle} id={`card-title-${index}`}>
                                            {card.title}
                                        </Card.Title>
                                        <Card.Text className={styles.productText}>
                                            {card.text}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>

                <Row className="mt-4">
                    <Col lg={4} md={6} sm={12}>
                        <Card className={styles.ecoCard} role="region" aria-labelledby="broadcast-alerts-card">
                            <Card.Body>
                                <Card.Title className={styles.cardTitle} id="broadcast-alerts-card">
                                    Broadcast panic alerts and mass notifications
                                </Card.Title>
                                <Card.Text>
                                    Panic buttons and mass notifications can send emergency and operational notifications via text, email, and voice to critical stakeholders, including local law enforcement, other personnel, and community members, immediately raising emergency alarms, enabling critical information sharing, and accelerating response coordination - for both planned events or crisis scenarios.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <Card className={styles.ecoCard} role="region" aria-labelledby="centralize-dispatch-card">
                            <Card.Body>
                                <Card.Title className={styles.cardTitle} id="centralize-dispatch-card">
                                    Centralize dispatch and media management
                                </Card.Title>
                                <Card.Text>
                                    Dispatch software from a single desktop application can streamline voice communications and share data such as live video and multimedia across multiple devices and networks, helping to ensure public safety agencies, enterprises, and communities have the right information to take actions with greater focus, accuracy, and speed.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <Card className={styles.ecoCard} role="region" aria-labelledby="communicate-across-teams-card">
                            <Card.Body>
                                <Card.Title className={styles.cardTitle} id="communicate-across-teams-card">
                                    Communicate across teams and technologies
                                </Card.Title>
                                <Card.Text>
                                    Radio and broadband-enabled devices can share voice and multimedia across networks and devices, including radios, smartphones, and laptops, enabling the interoperability and reach to keep disparate teams quickly and dynamically connected and informed.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default CommunicateInfo;
