import React from 'react';

const OperationsIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    className={className}
  >
    <path
      d="M22.34,37.16v-4.8c0-5.12,4.29-9.11,9.67-9.11s9.68,3.99,9.68,9.11v4.8c0,1.05,0,3.51-2.17,4.9m6.96-10.75h7.04v-2.64c0-1.4-.75-2.79-2.26-3.4-1.3-.53-5.42-2.16-5.42-2.16m-5.74,.91l2.29-.91s-1.07,.43-2.29,.91h0Zm-.6-6.12c0,2.7,2.05,5.61,4.62,5.61s4.62-2.86,4.62-5.61v-1.41c0-2.7-2.05-4.88-4.62-4.88s-4.62,2.18-4.62,4.88v1.41h0Zm-21.3,5.21s-4.12,1.64-5.42,2.16c-1.51,.61-2.26,2-2.26,3.4v2.64h7.12m4.01-8.21l2.33,.93c-1.22-.49-2.33-.93-2.33-.93h0Zm-6.34-5.21c0,2.7,2.05,5.61,4.62,5.61s4.62-2.86,4.62-5.61v-1.41c0-2.7-2.05-4.88-4.62-4.88s-4.62,2.18-4.62,4.88v1.41h0Zm4.61,14.4v3.34c0,1.34,1.08,2.42,2.42,2.42v-5.76h-2.42Zm21.77,0v5.76c1.34,0,2.42-1.08,2.42-2.42v-3.34h-2.42Zm-12.3,11.41s-6.28,2.53-8.26,3.34c-2.3,.94-3.45,3.09-3.45,5.26v4.08h28.67v-4.08c0-2.17-1.15-4.32-3.45-5.26-1.98-.81-8.26-3.34-8.26-3.34m-2.62,.62c3.86,0,7.04-4.43,7.04-8.66v-2.18c0-4.18-3.12-7.54-7.04-7.54s-7.04,3.37-7.04,7.54v2.18c0,4.18,3.12,8.66,7.04,8.66h0Zm-2.01-25.72V7.6H7.02V23.6h3m44,0h3V7.6h-23v11"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </svg>
);

export default OperationsIcon;
