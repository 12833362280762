// components/icons/MyIcon5.js

import React from 'react';

const Phone911Icon = ({ className }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    className={className}
  >
    <path
      d="M18.42,11.81h27.58M18.42,45.46h27.58m-14,7.54c1.1,0,2-.9,2-2s-.9-2-2-2-2,.9-2,2,.9,2,2,2Zm14-13.5v14.17c0,1.84-1.28,3.33-2.85,3.33H20.85c-1.57,0-2.85-1.49-2.85-3.33V10.33c0-1.84,1.28-3.33,2.85-3.33h22.3c1.57,0,2.85,1.49,2.85,3.33v13.58"
      style={{
        fill: 'none',
        stroke: 'currentColor',
        strokeMiterlimit: 10,
        strokeWidth: '2px',
      }}
    />
    <rect width="64" height="64" style={{ fill: 'none' }} />
    <g>
      <rect
        x="25.35"
        y="24.3"
        width="30.85"
        height="15.02"
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeMiterlimit: 10,
          strokeWidth: '2px',
        }}
      />
      <g>
        <path
          d="M30.3,34.23c.23,.02,.43,.02,.79,0,.55-.04,1.12-.19,1.54-.48,.5-.35,.84-.85,.98-1.44h-.04c-.35,.35-.85,.55-1.56,.55-1.32,0-2.43-.92-2.43-2.43s1.22-2.81,2.94-2.81c2,0,2.95,1.54,2.95,3.36,0,1.62-.52,2.81-1.37,3.59-.74,.67-1.76,1.04-2.97,1.09-.31,.02-.62,.01-.83,0v-1.43Zm1.1-3.91c0,.67,.36,1.25,1.1,1.25,.49,0,.84-.24,1.01-.52,.06-.11,.1-.23,.1-.46,0-.83-.31-1.64-1.14-1.64-.62,0-1.08,.55-1.07,1.37Z"
          style={{ fill: 'currentColor' }}
        />
        <path
          d="M39.37,31.79v1.21h-3.14v-1.21h3.14Z"
          style={{ fill: 'currentColor' }}
        />
        <path
          d="M41.25,29.37h-.02l-1.49,.71-.3-1.37,2.06-.96h1.51v7.8h-1.76v-6.18Z"
          style={{ fill: 'currentColor' }}
        />
        <path
          d="M47.88,31.79v1.21h-3.14v-1.21h3.14Z"
          style={{ fill: 'currentColor' }}
        />
        <path
          d="M49.93,29.37h-.02l-1.49,.71-.3-1.37,2.06-.96h1.51v7.8h-1.76v-6.18Z"
          style={{ fill: 'currentColor' }}
        />
      </g>
    </g>
  </svg>
);

export default Phone911Icon;
