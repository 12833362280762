import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import styles from "./radioPage.module.css";
import { RadioData } from './RadioData';
import ContactUsModal from '../ContactUsModal';

const images = [
  { id: '1', src: '/assets/radios2/prof-radios.jpg', title: 'Professional Radios', tag: 'Professional' },
  { id: '2', src: '/assets/radios2/comm-radios.jpg', title: 'Commercial Radios', tag: 'Commercial' },
  { id: '3', src: '/assets/radios2/mobile-radios.jpg', title: 'Mobile Radios', tag: 'Mobile' },
  { id: '4', src: '/assets/radios2/tlk-radios.jpg', title: 'Nation Wide Coverage', tag: 'LTE' },
  { id: '5', src: '/assets/bda/bda.jpg', title: 'In-Building Coverage (BDA)', tag: 'BDA' },
];

function Radio() {
  const { tag } = useParams();

  const isValidTag = tag && images.some(image => image.tag === tag);

  if (isValidTag) {
    const filteredData = RadioData
      .filter(item => item.tags.includes(tag))
      .sort((a, b) => b.rating - a.rating); // Sort by rating in descending order

    return (
      <div className={styles.main} role="main">
        <div
          className={styles.splashImage}
          style={{ backgroundImage: `url('/assets/home/pcola-beach1.jpg')` }}
          role="img"
          aria-label="Background image of a beach"
        />
        <h1>{images.find(image => image.tag === tag)?.title || 'Motorola Solutions Radios'}</h1>
        <Container>
          <Row>
            {filteredData.map((item) => (
              <Col key={item.id} md={4} sm={6} xs={12} className="mb-4">
                <Link 
                  to={`/radios/${tag}/${item.name}`} 
                  className={styles.cardLink} 
                  aria-label={`View details for ${item.title}`}
                >
                  <Card className={styles.card}>
                    <Card.Img 
                      variant="top" 
                      src={item.mainImage} 
                      className={styles.cardImage} 
                      alt={`Image of ${item.title}`} 
                    />
                    <Card.Body>
                      <Card.Title className={styles.cardTitle}>
                        {item.title}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    );
  } else {
    return (
      <div className={styles.main} role="main">
        <div
          className={styles.splashImage}
          style={{ backgroundImage: `url('/assets/home/pcola-beach1.jpg')` }}
          role="img"
          aria-label="Background image of a beach"
        />
        <h1>Motorola Solutions Radios</h1>
        <Container>
          <Row>
            {images.map((image, index) => (
              <Col key={index} md={4} sm={6} xs={12} className="mb-4">
                <Link 
                  to={`/radios/${image.tag}`} 
                  className={styles.cardLink} 
                  aria-label={`View details for ${image.title}`}
                >
                  <Card className={styles.card}>
                    <Card.Img 
                      variant="top" 
                      src={image.src} 
                      className={styles.cardImage} 
                      alt={`Image of ${image.title}`} 
                    />
                    <Card.Body>
                      <Card.Title className={styles.cardTitle}>
                        {image.title}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
        <ContactUsModal />
      </div>
    );
  }
}

export default Radio;
