import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import DomecamIcon from '../icons/DomecamIcon';
import VideoIcon from '../icons/VideoIcon';
import MassNoti from '../icons/MassNoti';
import OperationsIcon from '../icons/OperationsIcon';
import styles from './ecosystem.module.css';

const icons = [
  { id: 1, name: 'detect', component: DomecamIcon, info: 'Detection Technologies' },
  { id: 2, name: 'analyze', component: VideoIcon, info: 'Analyzing Technology' },
  { id: 3, name: 'communicate', component: MassNoti, info: 'Communication Technologies' },
  { id: 4, name: 'manage', component: OperationsIcon, info: 'Managing Technologies' },
];

const EcosystemNav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (iconName) => {
    navigate(`/ecosystem-${iconName}`);
  };

  return (
    <Container fluid>
      <div className={`${styles.EcoIconRow}`}>
        {icons.map((icon) => {
          const IconComponent = icon.component;
          const isActive = location.pathname.includes(icon.name);
          return (
            <div
              key={icon.id}
              className={`text-center ${styles.EcoIconItem} ${isActive ? styles.active : ''}`}
              onClick={() => handleClick(icon.name)}
              style={{ cursor: 'pointer' }}
            >
              <IconComponent className={styles.EcoIcon} />
              <div className={styles.EcoIconText}>{icon.info}</div>
            </div>
          );
        })}
        <div className={styles.EcoTitle}>
        
      </div>
      </div>
      {/* Static title below all buttons */}
      <div className={styles.EcoTitle}>
        
      </div>
    </Container>
  );
};

export default React.memo(EcosystemNav);
