import React from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import styles from "./ecosystem.module.css";
import EcosystemNav from "./EcosystemNav";

function ManageInfo() {
    return (
        <div className={styles.ecosystem} role="main">
            <div
                className={styles.splashImage}
                style={{ backgroundImage: `url('/assets/background/library-cam.jpeg')` }}
                role="img"
                aria-label="Background image showing managing technology"
            />
            <EcosystemNav />

            <h1>Motorola Solutions Ecosystem <br />Managing Technology</h1>

            <Container className="my-4">
                <Card className={`${styles.ecoCard} mb-4`} role="region" aria-labelledby="intro-card">
                    <Card.Body>
                        <Card.Title className={styles.cardTitle} id="intro-card">
                            Coordinate and manage effectively
                        </Card.Title>
                        <Card.Text>
                            Protect personnel, inform incident response, optimize records and evidence
                        </Card.Text>
                    </Card.Body>
                </Card>

                <Row className="mt-4">
                    <Col lg={4} md={6} sm={12}>
                        <Card className={styles.ecoCard} role="region" aria-labelledby="protect-personnel-card">
                            <Card.Body>
                                <Card.Title className={styles.cardTitle} id="protect-personnel-card">
                                    Protect personnel
                                </Card.Title>
                                <Card.Text>
                                    Body cameras can capture and share live video evidence and location,
                                    sharing first-hand perspective, offering an objective record of truth,
                                    deterring aggression and providing rich insights that can improve
                                    operational procedures, efficiency and safety for all.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <Card className={styles.ecoCard} role="region" aria-labelledby="inform-incident-response-card">
                            <Card.Body>
                                <Card.Title className={styles.cardTitle} id="inform-incident-response-card">
                                    Inform incident response
                                </Card.Title>
                                <Card.Text>
                                    Video security can provide eyes-on-scene and interoperable
                                    communications can share incident data and enable voice
                                    connectivity, helping to ensure responders and command/operations
                                    centers are informed and maintain situational awareness en route,
                                    during and after an incident.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <Card className={styles.ecoCard} role="region" aria-labelledby="optimize-records-card">
                            <Card.Body>
                                <Card.Title className={styles.cardTitle} id="optimize-records-card">
                                    Optimize records and evidence
                                </Card.Title>
                                <Card.Text>
                                    In-field reporting applications can collect and upload data such as
                                    images, audio and video files into a centralized record and evidence
                                    management system, enabling the ability to automatically tag,
                                    securely store and digitally share documents, reports and multimedia,
                                    to save time and double-handling while enhancing evidence
                                    completeness and accuracy.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ManageInfo;
