import React from 'react';

const PhoneBroadcast = ({ className }) => (
  <svg
    id="a"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    className={className}
  >
    <path
      d="M27.66,26.23c1.34-.96,2.8-1.44,4.39-1.44s3.02,.48,4.3,1.44m-13.52-2.88c2.72-2.56,5.78-3.85,9.17-3.85s6.45,1.28,9.17,3.85M18.42,11.81h27.58M18.42,45.46h27.58m-2.85,11.54H20.85c-1.57,0-2.85-1.49-2.85-3.33V10.33c0-1.84,1.28-3.33,2.85-3.33h22.3c1.57,0,2.85,1.49,2.85,3.33V53.67c0,1.84-1.28,3.33-2.85,3.33Zm-11.15-4c1.1,0,2-.9,2-2s-.9-2-2-2-2,.9-2,2,.9,2,2,2Z"
      fill="none"
      stroke='currentColor'
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </svg>
);

export default PhoneBroadcast;