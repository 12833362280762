const BodycamIcon = ({className}) => {
    return (
        <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        className={className}
      >
        <rect
          x="0.09"
          y="-0.02"
          width="63.91"
          height="63.91"
          style={{ fill: 'none' }}
        />
        <path
          d="M41.54,10.85l2.07,2.07v14.77l-5.31,1.76h-13.59l-5.31-1.76V12.92l2.07-2.07h20.08Zm-15.65,25.51v11.22l1.17,1.19h8.86l1.19-1.19v-11.22h-11.22Zm20.96,19.97l.89-.88c.37-.38,.58-.89,.59-1.43V30.29c0-.53,.21-1.05,.59-1.43h0c.38-.38,.6-.89,.6-1.43V10.8c0-.55-.21-1.07-.6-1.45l-1.76-1.75c-.38-.37-.89-.58-1.43-.59H17.31c-.53,0-1.05,.21-1.43,.59l-1.81,1.75c-.38,.38-.59,.89-.59,1.43V27.43c0,.53,.21,1.05,.59,1.43h0c.37,.38,.57,.9,.57,1.43v23.73c0,.54,.22,1.05,.6,1.43l.88,.88c.38,.38,.89,.59,1.43,.59h27.88c.53,0,1.04-.21,1.41-.59ZM26.18,18.83c0,2.93,2.38,5.31,5.31,5.31s5.31-2.38,5.31-5.31-2.38-5.31-5.31-5.31-5.31,2.38-5.31,5.31Zm2.98,0c0,1.28,1.04,2.32,2.32,2.32s2.32-1.04,2.32-2.32-1.04-2.32-2.32-2.32-2.32,1.04-2.32,2.32Zm-10.65,16.69c-.43,0-.79,.35-.79,.78v11.93c0,.09,.02,.18,.05,.26,.15,.41,.6,.62,1.01,.47l3.17-1.19c.3-.12,.49-.41,.49-.73v-9.55c0-.32-.2-.61-.49-.73l-3.17-1.19c-.08-.03-.17-.05-.26-.05Zm25.7,.05l-3.17,1.19c-.3,.12-.51,.41-.51,.73v9.55c0,.33,.2,.62,.51,.73l3.17,1.19c.4,.15,.85-.06,.99-.46,.03-.09,.05-.18,.05-.27v-11.93c0-.43-.34-.78-.77-.78-.09,0-.19,.02-.27,.05Zm-18.04-1.99h10.63"
          style={{
            fill: 'none',
            stroke: 'currentColor', // Use currentColor to inherit text color
            strokeMiterlimit: 10,
            strokeWidth: '2px'
          }}
        />
      </svg>
    );
}
export default  BodycamIcon