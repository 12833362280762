import React from 'react';

const InCarCamIcon = ({ className }) => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        className={className}
    >
        <defs>
            <style>
                {`.cls-1 {
                    opacity: 0;
                }

                .cls-1, .cls-2 {
                    fill: none;
                }

                .cls-2 {
                    stroke: currentColor; /* Updated to use currentColor */
                    stroke-miterlimit: 10;
                    stroke-width: 2px;
                }`}
            </style>
        </defs>
        <rect className="cls-1" x="0" width="64" height="64" />
        <g>
            <g>
                <path className="cls-2" d="m33.36,39h5.26-5.26Z" />
                <path className="cls-2" d="m21.95,55.29h21.05-21.05Z" />
                <path className="cls-2" d="m37.74,41.57l-2.63,5.14,2.63-5.14Z" />
                <path className="cls-2" d="m17.13,57c1.7,0,3.07-1.34,3.07-3s-1.38-3-3.07-3-3.07,1.34-3.07,3,1.37,3,3.07,3Z" />
                <path className="cls-2" d="m47.83,57c1.7,0,3.07-1.34,3.07-3s-1.37-3-3.07-3-3.07,1.34-3.07,3,1.38,3,3.07,3Z" />
                <polyline className="cls-2" points="22.26 46.41 40.04 46.41 43 44.71 40.57 41.57" />
                <polyline className="cls-2" points="7.04 56.14 7.04 51.57 7.88 49.29" />
                <polyline className="cls-2" points="12.3 55.29 9.67 55.29 9.67 51.25 11.3 48.03 22.26 46.41 29.56 41.57 41.74 41.57 49.73 44.8 54.6 44.8 56.96 51.25 55.2 55.29 52.71 55.29" />
            </g>
            <g>
                <g>
                    <line className="cls-2" x1="17.19" y1="17.36" x2="17.19" y2="19.12" />
                    <polygon className="cls-2" points="24.64 18.41 24.64 14.71 14.12 14.71 14.12 25.29 24.64 25.29 24.64 21.59 29.9 24.23 29.9 15.77 24.64 18.41" />
                </g>
                <rect className="cls-2" x="9.01" y="9" width="26" height="22" />
                <polyline className="cls-2" points="8.75 31.3 21.97 44 35.27 31.3" />
            </g>
        </g>
    </svg>
);

export default InCarCamIcon;
