const PhoneLockIcon = ({ className }) => (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 64 64"
      className={className}
    >
      <defs>
        <clipPath id="clippath">
          <path
            d="M46.43,44.37c0-1.06-.81-1.91-1.8-1.91s-1.8,.86-1.8,1.91c0,.78,.44,1.45,1.07,1.75l-.98,4.52h3.42l-.99-4.51c.64-.29,1.09-.97,1.09-1.76"
            style={{ clipRule: 'evenodd', fill: 'none' }}
          />
        </clipPath>
        <clipPath id="clippath-1">
          <rect width="64" height="64" style={{ fill: 'none' }} />
        </clipPath>
      </defs>
      <path
        d="M18.4,11.55h25.77m-25.77,31.82h15.92m10.3-7.27c-5.73,0-10.38,4.68-10.38,10.45s4.65,10.45,10.38,10.45,10.38-4.68,10.38-10.45-4.65-10.45-10.38-10.45m0,3.64c-3.74,0-6.77,3.05-6.77,6.82s3.03,6.82,6.77,6.82,6.77-3.05,6.77-6.82-3.03-6.82-6.77-6.82m-6.62,14.55H20.66c-1.47,0-2.66-1.41-2.66-3.15V10.15c0-1.74,1.19-3.15,2.66-3.15h20.84c1.47,0,2.66,1.41,2.66,3.15v25.85"
        style={{ fill: 'none', stroke: 'currentColor', strokeMiterlimit: 10, strokeWidth: '2px' }}
      />
      <g style={{ clipPath: 'url(#clippath)' }}>
        <g style={{ clipPath: 'url(#clippath-1)' }}>
          <rect x="37.82" y="37.45" width="13.61" height="18.18" style={{ fill: 'currentColor' }} />
        </g>
      </g>
      <rect width="64" height="64" style={{ fill: 'none', opacity: 0 }} />
    </svg>
  );
  
  export default PhoneLockIcon;