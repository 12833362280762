import React from 'react';
import { Row, Container, Col, Image, Card } from 'react-bootstrap';
import ContactUsModal from '../ContactUsModal';
import styles from './bda.module.css';
import { FaCheck } from 'react-icons/fa';

const Bda = () => {
    return (
        <div className={styles.main} role="main">
            <div
                className={styles.splashImage}
                style={{ backgroundImage: `url('/assets/home/pcola-beach1.jpg')` }}
                role="img"
                aria-label="Background image of a beach"
            >
            </div>
            <h1>In-Building Coverage (BDA)</h1>
            <Container className={styles.container}>
                <Row className="align-items-center">
                    <Col md={4} className="d-flex justify-content-center">
                        <Image 
                            src='/assets/bda/bda.jpg' 
                            className={`${styles.img1} rounded`} 
                            fluid 
                            alt="In-building coverage equipment"
                        />
                    </Col>
                    <Col>
                        <div className={styles.customCard}>
                            <p>
                                Are you struggling with limited two-way radio range or poor cell phone reception? Our solutions for In-building wireless mobile signal boosters will help improve and expand your network coverage for radios and cell phones. Businesses and agencies that often need this type of solution to boost mobile coverage are:
                            </p>
                        </div>
                        <div className={styles.customCard}>
                            <ul>
                                <li>Hospitals and medical centers</li>
                                <li>Public safety buildings: courts and jails</li>
                                <li>Large office buildings</li>
                                <li>Sports stadiums and recreational centers</li>
                                <li>Convention centers</li>
                                <li>Shopping malls & parking garages</li>
                                <li>Schools, colleges, and universities</li>
                                <li>Hotels and casinos</li>
                                <li>Manufacturing and production plants</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
               
            <div className={styles.das} role="region" aria-labelledby="das-heading">
                <Container>
                    <h4 id="das-heading">Public Safety DAS-ERRCS</h4>
                    <p className={styles.dasUnder}>
                        Distributed Antenna Systems (DAS) are installed in buildings to amplify radio signals. They help provide reliable communication for first responders who perform life-saving duties. Sometimes a public safety DAS is called an ERRCS (Emergency Responder Radio Communication System).
                    </p>
                </Container>
            
                <Container>
                    <Row className="align-items-center">
                        <Col md={4}>
                            <Image 
                                src='/assets/bda/bda-das.jpg' 
                                className={`${styles.img1} rounded`} 
                                fluid 
                                alt="Public Safety DAS equipment"
                            />
                        </Col>
                        <Col md={8}>
                            <Row>
                                <Col>
                                    <Card className={styles.dasCard} aria-labelledby="improve-radio-communications">
                                        <Card.Body className={styles.cardBody}>
                                            <Card.Title id="improve-radio-communications" className={styles.cardTitle}>
                                                <FaCheck /> Improve radio communications
                                            </Card.Title>
                                            <Card.Text className={styles.cardText}>
                                                Boost the signal indoors for communication reliability of any two-way radio system in any frequency band - public safety or private enterprise
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card className={styles.dasCard} aria-labelledby="comply-local-mandates">
                                        <Card.Body className={styles.cardBody}>
                                            <Card.Title id="comply-local-mandates" className={styles.cardTitle}>
                                                <FaCheck /> Comply with local mandates
                                            </Card.Title>
                                            <Card.Text className={styles.cardText}>
                                                Local jurisdictions may require adequate in-building coverage for public safety two-way radio systems before a building can be occupied.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card className={styles.dasCard} aria-labelledby="lower-cost-of-ownership">
                                        <Card.Body className={styles.cardBody}>
                                            <Card.Title id="lower-cost-of-ownership" className={styles.cardTitle}>
                                                <FaCheck /> Lower total cost of ownership
                                            </Card.Title>
                                            <Card.Text className={styles.cardText}>
                                                A properly designed DAS can support multiple two-way radio systems, both public safety and private.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <ContactUsModal />
            </div>
        </div>
    );
};

export default Bda;
