import React, { useState } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Row, Col } from 'react-bootstrap';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase.js';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './contact.module.css'; // Import your CSS module

const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    inquiry: '',
    location: '',
  });

  const [errors, setErrors] = useState({});
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.length === 10) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    }
    return phoneNumber;
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formData.firstName) formErrors.firstName = 'First Name is required';
    if (!formData.lastName) formErrors.lastName = 'Last Name is required';
    if (!formData.email) {
      formErrors.email = 'Email is required';
    } else if (!validateEmail(formData.email)) {
      formErrors.email = 'Please enter a valid email address';
    }
    if (!formData.inquiry) formErrors.inquiry = 'Inquiry is required';

    return formErrors;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Clear the error for the current field
    setErrors({ ...errors, [name]: '' });

    if (name === 'phoneNumber') {
      const formattedPhoneNumber = formatPhoneNumber(value);
      setFormData({ ...formData, phoneNumber: formattedPhoneNumber });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        setLoading(false);
        return;
    }

    if (!recaptchaToken) {
        alert('Please complete the CAPTCHA.');
        setLoading(false);
        return;
    }

    try {
        // Create a copy of formData without the recaptchaToken
        const submissionData = { ...formData };

        await addDoc(collection(db, 'contacts'), submissionData);
        alert('Form submitted successfully!');
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            inquiry: '',
            location: '',
        });
        setRecaptchaToken(null);
        setErrors({});
    } catch (error) {
        alert('Error submitting form. Please try again.');
    } finally {
        setLoading(false);
    }
};


  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <h2>Contact Us</h2>
        <Row>
          <Col md={6}>
            <FormGroup controlId="firstName">
              <FormLabel>First Name</FormLabel>
              <FormControl
                type="text"
                name="firstName"
                placeholder="John"
                value={formData.firstName}
                onChange={handleInputChange}
                isInvalid={!!errors.firstName}
              />
              <FormControl.Feedback type="invalid">
                {errors.firstName}
              </FormControl.Feedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup controlId="lastName">
              <FormLabel>Last Name</FormLabel>
              <FormControl
                type="text"
                name="lastName"
                placeholder="Doe"
                value={formData.lastName}
                onChange={handleInputChange}
                isInvalid={!!errors.lastName}
              />
              <FormControl.Feedback type="invalid">
                {errors.lastName}
              </FormControl.Feedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup controlId="email">
              <FormLabel>Email Address</FormLabel>
              <FormControl
                type="text"
                name="email"
                placeholder="example@example.com"
                value={formData.email}
                onChange={handleInputChange}
                isInvalid={!!errors.email}
              />
              <FormControl.Feedback type="invalid">
                {errors.email}
              </FormControl.Feedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup controlId="phoneNumber">
              <FormLabel>Phone Number</FormLabel>
              <FormControl
                type="tel"
                name="phoneNumber"
                placeholder="(123) 456-7890"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup controlId="location">
          <FormLabel>Where are you located?</FormLabel>
          <FormControl
            type="text"
            name="location"
            placeholder="City, Country"
            value={formData.location}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup controlId="inquiry">
          <FormLabel>Inquiry</FormLabel>
          <FormControl
            as="textarea"
            rows={3}
            name="inquiry"
            placeholder="Enter your inquiry here"
            value={formData.inquiry}
            onChange={handleInputChange}
            isInvalid={!!errors.inquiry}
          />
          <FormControl.Feedback type="invalid">
            {errors.inquiry}
          </FormControl.Feedback>
        </FormGroup>

        <div className="d-flex flex-column flex-md-row align-items-end">
          <div className="recaptcha-container me-md-3">
            <ReCAPTCHA
              sitekey={siteKey}
              onChange={(token) => setRecaptchaToken(token)}
            />
          </div>
          <div className="button-container">
            <button
              type="submit"
              className={`${styles.customButton} ${loading ? styles.loadingButton : ''}`}
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ContactForm;
