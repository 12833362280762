import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import styles from "./rentals.module.css";

const Rental = () => {
    return (
        <div className={styles.pageWrapper} role="main">
            <div
                className={styles.splashImage}
                style={{ backgroundImage: `url('/assets/home/pcola-beach1.jpg')` }}
                role="img"
                aria-label="Background image of a beach"
            >
            </div>
            <h1 className={styles.title} id="rental-title">Rental Radios</h1>
            <h2 className={styles.subheading} id="rental-subheading">Business Rental Solutions for Any Event</h2>
            <Container className={styles.container}>
                <Row>
                    <Col md={12}>
                        <p className={styles.introText}>
                            Ownership has its advantages, but renting can be more practical in business scenarios. For example, construction projects that span several months and require constant communication benefit greatly from renting rugged, portable radios. This approach not only boosts productivity but also enhances worker safety.
                        </p>
                    </Col>
                </Row>
                
                <Row>
                    <Col md={4}>
                        <Image
                            src='/assets/rentals/Rental4radios.jpg'
                            className={styles.img}
                            fluid
                            alt="Four portable radios"
                            role="img"
                            aria-label="Four portable radios"
                        />
                    </Col>
                    
                    <Col md={8}>
                        <p className={styles.introText}>
                            Our rental plans offer <span className='emph'>UHF Portable Radios</span>, <span className="emph">Repeaters</span>, and <span className="emph">Intrinsically Safe Radios</span>, ideal for seasonal industries, special projects, and events. Renting reduces initial costs and provides flexible terms, ensuring you have the right equipment when needed.
                        </p>
                        
                        <ul className={styles.detailsList}>
                            <li>Weekly and Monthly rates are available for quote.</li>
                            <li>Packages include radio, mic, battery, spare battery, and charger.</li>
                        </ul>
                        
                        <p className={styles.introText}>
                            For more information, please contact Robbin Soto at <strong>(228) 762-3721</strong>.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Rental;
