import React from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import styles from "./ecosystem.module.css";
import EcosystemNav from "./EcosystemNav";

function AnalyzeInfo() {
    return (
        <div className={styles.ecosystem} role="main">
            <div
                className={styles.splashImage}
                style={{ backgroundImage: `url('/assets/background/library-cam.jpeg')` }}
                role="img"
                aria-label="Background image showing analysis technology"
            />
            <EcosystemNav />

            <h1>Motorola Solutions Ecosystem <br/>Analyze Technologies</h1>

            <Container className="my-4">
                <Card className={`${styles.ecoCard} mb-4`} role="region" aria-labelledby="intro-card">
                    <Card.Body>
                        <Card.Title className={styles.cardTitle} id="intro-card">
                            Gather insights quickly
                        </Card.Title>
                        <Card.Text>
                            Accelerate actions, locate resources, see in a single view
                        </Card.Text>
                    </Card.Body>
                </Card>

                <Row className="mt-4">
                    <Col lg={4} md={6} sm={12}>
                        <Card className={styles.ecoCard} role="region" aria-labelledby="accelerate-actions-card">
                            <Card.Body>
                                <Card.Title className={styles.cardTitle} id="accelerate-actions-card">
                                    Accelerate actions
                                </Card.Title>
                                <Card.Text>
                                    AI-powered analytics can filter video footage to highlight what’s
                                    important according to defined triggers or characteristics, helping to
                                    rapidly locate an individual, object or atypical activity in real-time and
                                    enabling the insights to anticipate what’s next, prevent an event or
                                    accelerate response.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <Card className={styles.ecoCard} role="region" aria-labelledby="locate-resources-card">
                            <Card.Body>
                                <Card.Title className={styles.cardTitle} id="locate-resources-card">
                                    Locate resources
                                </Card.Title>
                                <Card.Text>
                                    GPS real-time location can show the closest resources on a map,
                                    including personnel and assets, helping to locate inventory, recognize
                                    hazardous situations or enable a safer and more efficient response to
                                    manage situations with greater confidence.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                        <Card className={styles.ecoCard} role="region" aria-labelledby="see-in-view-card">
                            <Card.Body>
                                <Card.Title className={styles.cardTitle} id="see-in-view-card">
                                    See in a single view
                                </Card.Title>
                                <Card.Text>
                                    Command center software can aggregate various video feeds,
                                    including fixed, mobile and body cameras, offering multiple
                                    perspectives in a single view for better operational understanding
                                    and actionable perspective.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default AnalyzeInfo;
