import React from 'react';

const MassNoti = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 129.43 164.15"
    className={className}
  >
    <defs>
      <style>
        {`
          .str0 {stroke:currentColor;stroke-width:6.31;stroke-miterlimit:10;}
          .fil0 {fill:none;}
        `}
      </style>
    </defs>
    <g id="Layer_x0020_1">
      <path
        className="fil0 str0"
        d="M40.09 142.94l-31.75 0 0 -72.5 -5.18 -2.87 0 -14.9 5.18 0 51.82 0 5.18 0 0 32.15 -5.18 3.27 0 6.9m-50.84 -42.32l9.23 0 0 -12.38 -9.23 0 0 12.38zm18.47 0l9.23 0 0 -15.47 -9.23 0 0 15.47zm-9.23 32.5l30.78 0 -30.78 0zm3.08 26.31l24.62 0 0 -15.48 -24.62 0 0 15.48zm-3.08 -35.59l30.78 0 -30.78 0zm0 -9.28l30.78 0 -30.78 0zm31.25 -14.44l0 -18.91 1.29 -2.36 0 -23.86c0,-2.14 1.74,-3.87 3.89,-3.87 2.15,0 3.89,1.73 3.89,3.87l0 23.86 1.29 2.36 0 18.91 -10.36 0zm42.62 90.27l28.71 0c2.84,0 5.15,-2.7 5.15,-6.02l0 -78.23c0,-3.32 -2.31,-6.02 -5.15,-6.02l-40.27 0c-2.84,0 -5.15,2.69 -5.15,6.02l0 36.82m27.04 31.97c0,1.75 -1.41,3.17 -3.16,3.17 -1.74,0 -3.16,-1.42 -3.16,-3.17 0,-1.75 1.41,-3.17 3.16,-3.17 1.74,0 3.16,1.42 3.16,3.17zm-62.65 34.03l26.16 -52.61 26.17 52.61 -52.32 0zm26.16 -30.95l0 12.38 0 -12.38zm0 18.57l0 6.19 0 -6.19z"
      />
    </g>
  </svg>
);

export default MassNoti;
